import { createTheme } from '@mui/material/styles'
declare module '@mui/material/styles' {
  interface CustomTheme {
    typography?: {
      fontFamily: 'Poppins'
      style: 'normal'
    }
    shape?: {
      borderRadius: '20'
    }
    body?: '#f8f8f8'
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}
export const theme = createTheme({
  palette: {
    primary: {
      light: '#021746',
      main: '#20ABE6',
      dark: '#021746',
      contrastText: '#FFF'
    },
    secondary: {
      light: '#20ABE6',
      main: '#20ABE6',
      dark: '#20ABE6',
      contrastText: '#FFF'
    }
  }
})
