import React, { FC } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { getUserDetails, Logout } from "../helpers/util";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { paths } from "../routing/paths";
import Logo from "../components/logo/home.png";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { List, ListItem, ListItemText } from "@mui/material";
import { matchPath, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
const useStyles = makeStyles((theme) => ({
  sidebarSelected: {
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    marginRight: "30px",
    borderRadius: "14px",
    "&:hover": {
      backgroundColor: "#FFF !important",
      background: "#FFF !important",
      color: "#20ABE6",
      borderRadius: "14px",
    },
    "&:active": {
      backgroundColor: "#FFF !important",
      background: "#FFF !important",
      color: "#20ABE6",
      borderRadius: "14px",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFF !important",
      background: "#FFF !important",
      color: "#20ABE6",
      borderRadius: "14px",
    },
  },
  MuiListItemText: {
    "&.MuiListItemText-root": {
      margin: "30px",
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
}));
const menu = [
  {
    name: "Files",
    path: paths.ADMIN_FILES,
    index: 0,
    isParrent: true,
    hasChild: false,
    isVisible: true,
  },
  {
    name: "Users",
    path: paths.ADMIN_USERLIST,
    index: 1,
    isParrent: true,
    hasChild: false,
    isVisible: true,
  },

  {
    name: "Profile",
    path: paths.ADMIN_PROFILE,
    index: 2,
    isParrent: true,
    hasChild: false,
    isVisible: false,
  },
];
interface ITheme {
  history: string;
  _theme?: {
    breakpoints: any;
  };
  breakpoints?: any;
}
interface Props {
  props?: any;
  history?: any;
}
interface Props {
  props?: any;
  history?: any;
}
const Header: FC<Props> = ({ props }) => {
  const matches = useMediaQuery(
    (_theme: ITheme) => _theme?.breakpoints?.down("sm") ?? "600"
  );

  const location = useLocation();
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const classes = useStyles();
  const handleListItemClick = (event: any, path: any, index: any) => {
    history.push(path);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme<any>();

  const user = getUserDetails();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    history.push(paths.ADMIN_SIGNIN);
    Logout();
  };
  const handleProfile = () => {
    setAnchorEl(null);
    history.push(paths.ADMIN_PROFILE);
  };
  React.useEffect(() => {}, [user]);
  React.useEffect(() => {
    if (location) {
      const currentRoute = menu.find((route) =>
        matchPath(location.pathname, route)
      );
      if (currentRoute) {
        setSelectedIndex(currentRoute.index);
      }
    }
  }, [location]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ bgcolor: theme.primary }}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "42px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {user.role !== "1" ? (
                <Link to={"/admin/users_list"}>
                  <img width={"100%"} height={"100%"} src={Logo} alt="logo" />
                </Link>
              ) : (
                <Link to={"/files"}>
                  <img width={"100%"} height={"100%"} src={Logo} alt="logo" />
                </Link>
              )}
              {user.role !== "1" ? (
                <Link
                  to={"/admin/users_list"}
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: "20px",
                    }}
                  >
                    <b>{"USAMVBT "}</b>
                  </Typography>
                </Link>
              ) : (
                <Link
                  to={"/files"}
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: "20px",
                    }}
                  >
                    <b>{"USAMVBT "}</b>
                  </Typography>
                </Link>
              )}
            </Box>
          </Box>
          {user.role !== "1" && (
            <>
              {!matches && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <List
                      component="nav"
                      aria-label=""
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {menu.map((item) => (
                        <>
                          {item.isVisible === true && (
                            <>
                              <ListItem
                                key={item.index}
                                className={classes.sidebarSelected}
                                button
                                selected={selectedIndex === item.index}
                                onClick={(event) =>
                                  handleListItemClick(
                                    event,
                                    item.path,
                                    item.index
                                  )
                                }
                              >
                                <ListItemText
                                  className={classes.MuiListItemText}
                                  primary={item.name}
                                ></ListItemText>
                              </ListItem>
                            </>
                          )}
                        </>
                      ))}
                    </List>
                  </Box>
                </>
              )}
            </>
          )}

          <Box>
            <IconButton
              aria-controls="header-menu"
              aria-haspopup="true"
              onClick={handleClick}
              color="primary"
            >
              <Typography
                style={{
                  color: "#fff",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {user?.firstName + " " + user?.lastName}
              </Typography>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Typography
                sx={{
                  padding: "5px 16px 5px 16px",
                }}
              >
                {user?.email}
              </Typography>
              {matches && (
                <>
                  {menu.map((item) => (
                    <>
                      {item.isVisible === true && (
                        <>
                          <MenuItem
                            key={item.index}
                            onClick={(event) => {
                              handleListItemClick(event, item.path, item.index);
                              setAnchorEl(null);
                            }}
                          >
                            <Typography> {item.name} </Typography>
                          </MenuItem>
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
              <MenuItem onClick={handleProfile}>
                <Typography> Profile</Typography>
              </MenuItem>
            </Menu>
            <IconButton
              aria-controls="header-menu"
              aria-haspopup="true"
              onClick={handleLogout}
              color="primary"
              style={{
                borderLeft: "2px solid #FFF",
                borderRadius: "0px",
              }}
            >
              <LogoutRoundedIcon
                style={{ color: "#ffffff", marginRight: "5px" }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
