import { Box, Grid, Typography } from "@mui/material";
import { FilePaginationItem } from "../api/fetch-client";
import { NoData } from "./icons/NoData";
import { getUserDetails } from "../helpers/util";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Skeleton from "@mui/material/Skeleton";
import AddFile from "../pages/shared/files/AddFile";
import DevFiles from "./DevFiles";
interface FolderProps {
  isLoading?: boolean;
  files?: FilePaginationItem[] | [];
  handleClick?: any;
  handleDialogClose?: any;
  selectedItem?: FilePaginationItem | undefined;
  currentFolderId?: number;
  lastElementRef?: any;
  setFoldersFiles?: any;
  foldersFiles?: any;
  scroll?:any
}

function AllFiles(props: FolderProps) {
  const user = getUserDetails();
  const {
    isLoading,
    files,
    handleClick,
    handleDialogClose,
    selectedItem,
    currentFolderId,
    lastElementRef,
    setFoldersFiles,
    foldersFiles,
    scroll
  } = props;

  return (
    <>
      <Grid container spacing={8} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>Files</b>
          </Typography>
        </Grid>
        {user.role !== "1" && (
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: "30px !important",
              paddingBottom: "15px !important",
            }}
          >
            <AddFile
              type={"dragAndDrop"}
              handleClose={handleDialogClose}
              currentFolderId={currentFolderId}
              setFoldersFiles={setFoldersFiles}
              foldersFiles={foldersFiles}
              scroll={scroll}
            />
          </Grid>
        )}
        {!isLoading ? (
          <>
            {files && files.length > 0 ? (
              <>
                {files &&
                  files.map((item: any, i: any) => {
                    let extension: any =
                      item?.name &&
                      item?.name.substring(item?.name.lastIndexOf(".") + 1);
                    if (files.length === i + 1) {
                      return (
                        <Grid
                          id={`${item?.scroll}`}
                          item
                          xs={12}
                          sm={6}
                          lg={2.3}
                          md={3}
                          key={i}
                          sx={{ paddingTop: "30px !important" }}
                          ref={lastElementRef}
                        >
                          <Box
                            sx={{
                              border: "0.5px solid #2A2A2A",
                              backgroundColor:
                                item.id === selectedItem?.id ? "#e8f0fe" : "",
                              background: item?.scroll ? "#e8f0fe" : "",
                              borderRadius: "04px",
                              cursor: "pointer",
                            }}
                            aria-controls="account-menu"
                            aria-haspopup="true"
                          >
                            <Typography
                              sx={{
                                cursor: "pointer",
                                textAlign: "right",
                                padding: "5px 5px 0px 0px",
                              }}
                              onClick={(e) => {
                                handleClick(e, item);
                              }}
                            >
                              <MoreVertIcon />
                            </Typography>
                            <DevFiles name={item.name} extension={extension} />
                          </Box>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          id={`${item?.scroll}`}
                          item
                          xs={12}
                          sm={6}
                          lg={2.3}
                          md={3}
                          key={i}
                          sx={{ paddingTop: "30px !important" }}
                        >
                          <Box
                            sx={{
                              border: "0.5px solid #2A2A2A",
                              backgroundColor:
                                item.id === selectedItem?.id ? "#e8f0fe" : "",
                              background: item?.scroll ? "#e8f0fe" : "",
                              borderRadius: "04px",
                              cursor: "pointer",
                            }}
                            aria-controls="account-menu"
                            aria-haspopup="true"
                          >
                            <Typography
                              sx={{
                                cursor: "pointer",
                                textAlign: "right",
                                padding: "5px 5px 0px 0px",
                              }}
                              onClick={(e) => {
                                handleClick(e, item);
                              }}
                            >
                              <MoreVertIcon />
                            </Typography>
                            <DevFiles name={item.name} extension={extension} />
                          </Box>
                        </Grid>
                      );
                    }
                  })}
              </>
            ) : (
              <NoData dataType="files" />
            )}
          </>
        ) : (
          <>
            {[1, 2, 3, 4, 5].map((item, i) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={2.3}
                md={2.3}
                key={i}
                sx={{ paddingTop: "30px !important" }}
              >
                <Box
                  sx={{
                    border: "0.5px solid #2A2A2A",
                    borderRadius: "04px",
                  }}
                  aria-controls="account-menu"
                  aria-haspopup="true"
                >
                  <Box
                    sx={{
                      padding: "20px 15px 15px",
                      height: "150px",
                    }}
                  >
                    <Box
                      sx={{
                        mt: 2,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton animation="wave" width={70} height={80} />
                    </Box>
                    <Box sx={{ marginTop: "30px" }}>
                      <Skeleton
                        animation="wave"
                        height={20}
                        width="15%"
                        sx={{
                          display: "inline-block",
                          marginRight: 2,
                        }}
                      />

                      <Skeleton
                        animation="wave"
                        height={20}
                        width="75%"
                        sx={{ display: "inline-block" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
}

export default AllFiles;
