import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const PptIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 360 500"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M337.079 105.618L233.146 0H0V500H337.079V461.8H398.878V264.609H337.079V105.618ZM221.91 12.3626L325.843 116.857H221.91V12.3626ZM382.022 281.464V444.947H64.6067V281.464H382.022Z"
        fill={fill}
      />
      <path
        d="M132.584 378.652H134.269H137.079H139.889H141.574C145.509 378.652 149.439 378.091 153.374 376.967C157.309 375.842 160.678 374.157 163.484 371.911C166.294 369.666 169.104 366.856 170.789 362.922C172.474 358.987 173.598 355.057 173.598 350.001C173.598 344.382 172.474 339.326 170.228 335.956C167.983 332.586 165.173 329.776 162.363 327.531C158.993 325.286 155.623 324.161 151.128 323.597C147.194 323.036 142.703 322.472 138.769 322.472C134.834 322.472 130.904 322.472 126.969 323.032C123.034 323.593 119.104 324.157 116.294 324.717V406.742H132.584L132.584 378.652ZM132.584 337.642C133.709 337.642 134.83 337.081 135.954 337.081H140.449C142.695 337.081 144.384 337.081 146.629 337.642C148.314 338.202 150.564 338.766 151.685 339.887C153.37 341.012 154.494 342.133 155.619 343.822C156.744 345.507 157.304 347.756 157.304 350.002C157.304 352.811 156.744 355.621 155.619 357.306C154.494 358.991 153.374 360.676 151.685 361.801C150 362.926 148.314 363.486 146.065 364.046C143.82 364.607 142.131 364.607 139.885 364.607H138.2H135.954H133.709H132.024V337.642H132.584Z"
        fill={fill}
      />
      <path
        d="M215.169 378.652H216.854H219.664H222.474H224.159C228.094 378.652 232.024 378.091 235.959 376.967C239.894 375.842 243.263 374.157 246.069 371.911C248.879 369.666 251.689 366.856 253.374 362.922C255.059 358.987 256.183 355.057 256.183 350.001C256.183 344.382 255.059 339.326 252.813 335.956C250.568 332.586 247.758 329.776 244.948 327.531C241.578 325.286 238.208 324.161 233.713 323.597C229.779 323.036 225.288 322.472 221.354 322.472C217.42 322.472 213.489 322.472 209.554 323.032C205.619 323.593 201.689 324.157 198.879 324.717V406.742H215.169L215.169 378.652ZM215.169 337.642C216.294 337.642 217.415 337.081 218.539 337.081H223.034C225.28 337.081 226.969 337.081 229.214 337.642C230.899 338.202 233.149 338.766 234.27 339.887C235.955 341.012 237.079 342.133 238.204 343.822C239.329 345.507 239.889 347.756 239.889 350.002C239.889 352.811 239.329 355.621 238.204 357.306C237.079 358.991 235.959 360.676 234.27 361.801C232.584 362.926 230.899 363.486 228.65 364.046C226.404 364.607 224.716 364.607 222.47 364.607H220.785H218.539H216.294H214.609V337.642H215.169Z"
        fill={fill}
      />
      <path
        d="M288.764 337.079H299.999V393.819H286.518V406.74H329.773V393.819H316.292V337.079H327.527V351.685H340.448V324.158H275.843V351.685H288.763L288.764 337.079Z"
        fill={fill}
      />
    </svg>
  );
};

export default PptIcon;
