import React, { FC } from 'react'
interface Props {
  fill?: any
  width?: number
  height?: number
}
const DocumentIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 62 79'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M61.1722 19.7889C61.7006 20.3173 62 21.0307 62 21.7793V76.0909C62 77.6497 60.7406 78.9091 59.1818 78.9091H2.81818C1.25937 78.9091 0 77.6497 0 76.0909V2.81818C0 1.25937 1.25937 0 2.81818 0H40.2207C40.9693 0 41.6915 0.299432 42.2199 0.827841L61.1722 19.7889ZM55.5006 23.0739L38.9261 6.49943V23.0739H55.5006ZM31 44.219L35.6509 61.5685C35.711 61.7934 35.8438 61.9922 36.0285 62.1339C36.2133 62.2757 36.4396 62.3524 36.6725 62.3523H39.4704C39.703 62.3525 39.9292 62.276 40.1139 62.1346C40.2986 61.9932 40.4315 61.7948 40.492 61.5702L47.0416 37.2634C47.0654 37.1738 47.0772 37.0814 47.0768 36.9886C47.0768 36.7084 46.9655 36.4395 46.7673 36.2414C46.5691 36.0432 46.3003 35.9318 46.02 35.9318H42.8874C42.6483 35.9317 42.4161 36.0127 42.2289 36.1617C42.0418 36.3106 41.9107 36.5186 41.857 36.7517L37.8253 54.2861L33.443 36.7324C33.3859 36.5037 33.2539 36.3008 33.0682 36.1557C32.8825 36.0107 32.6536 35.9319 32.4179 35.9318H29.5821C29.3463 35.9319 29.1173 36.0108 28.9316 36.1561C28.7458 36.3013 28.614 36.5044 28.557 36.7332L24.1835 54.2509L20.1236 36.7509C20.0697 36.5184 19.9387 36.311 19.7519 36.1624C19.5651 36.0139 19.3336 35.9329 19.0949 35.9327H15.9782C15.8857 35.9327 15.7936 35.9448 15.7043 35.9688C15.4338 36.0416 15.2032 36.2189 15.0633 36.4617C14.9235 36.7044 14.8857 36.9928 14.9584 37.2634L21.4904 61.5702C21.5508 61.7945 21.6834 61.9927 21.8678 62.1341C22.0521 62.2754 22.2779 62.3521 22.5102 62.3523H25.3275C25.5602 62.3522 25.7864 62.2754 25.9709 62.1336C26.1555 61.9919 26.2881 61.7933 26.3482 61.5685L30.9991 44.219H31Z'
        fill={fill}
      />
    </svg>
  )
}

export default DocumentIcon
