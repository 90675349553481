import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { RouteComponentProps } from '@reach/router'

import { paths } from './paths'
import { isSignin } from '../helpers/util'
import Header from '../components/Header'
import { Grid } from '@mui/material'
type Props = {
  component: FC
} & RouteComponentProps

const PrivateRoute: FC<Props> = ({
  component: Component,
  location: string,
  ...rest
}) => {
  const isLogin = isSignin()
  return (
    <>
      <Route
        {...rest}
        render={props =>
          isLogin ? (
            <Grid container direction='column'>
              <Grid container item xs={12}>
                <Header history={props.history} />
              </Grid>

              <Grid container item pt={6} pb={6} xs={12}>
                <Grid
                  item
                  xl={12}
                  sm={12}
                  sx={{
                    p: '25px '
                  }}
                >
                  <Component {...rest} />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>{<Redirect to={{ pathname: paths.DEFAULT }} />}</>
          )
        }
      />
    </>
  )
}

export default PrivateRoute
