import React, { FC } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'
const EditIcon: FC = () => {
  const theme = useTheme<any>()

  return (
    <Box sx={{ mr: '12px' }}>
      <svg
        width={14}
        height={14}
        viewBox='0 0 14 14'
        fill={theme.palette.primary.main}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.62029 10.5553L5.62025 10.5552L5.6179 10.5578C5.61086 10.5653 5.59847 10.5728 5.57963 10.5767L2.63871 11.0925C2.60723 11.0972 2.57786 11.1 2.54975 11.1C2.42163 11.1 2.3011 11.0501 2.20323 10.9553C2.14723 10.8998 2.10556 10.8315 2.08184 10.7563C2.05807 10.6809 2.05302 10.6009 2.06714 10.5231L2.06725 10.5225L2.58326 7.58039C2.58676 7.56369 2.5937 7.55072 2.60471 7.53971L10.023 0.121461C10.0368 0.107654 10.0548 0.1 10.0765 0.1C10.0982 0.1 10.1162 0.107654 10.13 0.121461L13.0385 3.03169L13.0386 3.03178C13.0455 3.03869 13.051 3.04688 13.0548 3.0559C13.0585 3.06493 13.0604 3.0746 13.0604 3.08437C13.0604 3.09415 13.0585 3.10382 13.0548 3.11285C13.051 3.12187 13.0455 3.13006 13.0386 3.13697L13.0385 3.13704L5.62029 10.5553ZM3.65855 8.07728L3.63621 8.09962L3.63075 8.13073L3.35775 9.68823L3.33311 9.82879L3.47364 9.80398L5.02939 9.52923L5.06042 9.52375L5.0827 9.50147L11.43 3.15597L11.5007 3.08526L11.43 3.01454L10.1472 1.73179L10.0765 1.66109L10.0058 1.73178L3.65855 8.07728ZM0.56 12.77H13.44C13.6945 12.77 13.9 12.9755 13.9 13.23V13.86C13.9 13.8818 13.8818 13.9 13.86 13.9H0.14C0.118229 13.9 0.1 13.8818 0.1 13.86V13.23C0.1 12.9755 0.305478 12.77 0.56 12.77Z'
          fill='black'
          stroke='white'
          strokeWidth='0.2'
        />
      </svg>
    </Box>
  )
}

export default EditIcon
