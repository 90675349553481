import React, { FC } from 'react'
interface Props {
  fill?: any
  width?: number
  height?: number
}
const MediaIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 62 62'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.1 12.4C1.395 12.4 0 13.795 0 15.5V55.8C0 59.21 2.79 62 6.2 62H46.5C48.205 62 49.6 60.605 49.6 58.9C49.6 57.195 48.205 55.8 46.5 55.8H9.3C7.595 55.8 6.2 54.405 6.2 52.7V15.5C6.2 13.795 4.805 12.4 3.1 12.4ZM55.8 0H18.6C15.19 0 12.4 2.79 12.4 6.2V43.4C12.4 46.81 15.19 49.6 18.6 49.6H55.8C59.21 49.6 62 46.81 62 43.4V6.2C62 2.79 59.21 0 55.8 0ZM31 38.75V10.85L47.957 23.56C48.794 24.18 48.794 25.42 47.957 26.04L31 38.75Z'
        fill={fill}
      />
    </svg>
  )
}

export default MediaIcon
