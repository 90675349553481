import React, { FC } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button
} from '@mui/material'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles<any>(theme => ({
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
  },
  dialogTitle: {
    textAlign: 'center'
  },
  dialogContent: {
    textAlign: 'center'
  },
  dialogAction: {
    justifyContent: 'center'
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'default',
      color: '#000'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '4rem',
      color: '#fff'
    }
  }
}))
interface Props {
  confirmDialog?: any
  setConfirmDialog?: any
}
const DevConfirmDialog: FC<Props> = ({ confirmDialog, setConfirmDialog }) => {
  const classes = useStyles()
  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocationIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant='h6'>{confirmDialog.title}</Typography>
        <Typography variant='subtitle2'>{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        >
          No
        </Button>
        <Button
          color='primary'
          onClick={() => confirmDialog.onConfirm()}
          sx={{
            ':hover': {
              bgcolor: 'primary.dark',
              color: 'white'
            }
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DevConfirmDialog
