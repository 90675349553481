import React, { FC, useState } from 'react'
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search'
import { Visibility, VisibilityOff } from '@mui/icons-material'
const useHelperTextStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: '-20px',
    right: '-15px',
    color: '#d32f2f'
  },
  numberField: {
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0'
    },
    '& input[type=number]': {
      mozAppearance: 'textfield'
    }
  }
}))
const useStyles = makeStyles(() => ({
  textBox: {
    margin: '10px 0px 10px 0px'
  },
  resize: {
    fontSize: '1rem'
  },
  root: {
    error: {
      position: 'absolute',
      bottom: '-20px',
      right: '-15px',
      color: '#d32f2f'
    }
  }
}))
interface Props {
  HeaderText?: string
  value?: any
  id?: string
  label?: string
  type?: string
  onChange?: any
  onKeyDown?: any
  name?: any
  customClassName?: string
  extension?: string
  isProtected?: any
  helperText?: any
  search?: boolean
  prize?: boolean
  onBlur?: any
  placeholder?: string
  error?: any,
  onKeyPress?: (e:any) => void,
  autoComplete?:string
}

const MuiTextField: FC<Props> = ({
  HeaderText,
  value,
  id,
  label,
  type,
  onChange,
  onKeyDown,
  name,
  customClassName,
  extension,
  isProtected,
  helperText,
  search,
  prize,
  onBlur,
  placeholder,
  error,
  onKeyPress,
  autoComplete,
  ...rest
}) => {
  const [isShowPassword, setIsShowPassword] = useState(false)

  const classes = useStyles()
  const toggleEyeIcon = () => {
    setIsShowPassword(!isShowPassword)
  }
  const helperTextStyles = useHelperTextStyles()

  return (
    <>
      <Typography>{HeaderText}</Typography>
      <TextField
        sx={{ background: '#fff', borderRadius: '10px' }}
        id={id}
        autoComplete={autoComplete}
        error={error}
        helperText={helperText ? helperText : ' '}
        label={label}
        name={name}
        type={isProtected ? (isShowPassword ? 'string' : 'password') : type}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onBlur={onBlur}
        value={value}
        variant='outlined'
        fullWidth
        size='small'
        className={`${customClassName} input-custom ${helperTextStyles.numberField}`}
        color='primary'
        InputProps={{
          classes: {
            input: classes.resize
          },
          startAdornment: search && (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: extension ? (
            <InputAdornment position='end'>{extension}</InputAdornment>
          ) : (
            isProtected && (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => toggleEyeIcon()}
                  edge='end'
                >
                  {isShowPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          )
          
        }}
        FormHelperTextProps={{
          classes: {
            root: helperTextStyles.root
          }
        }}
        {...rest}
      />
    </>
  )
}
export default MuiTextField
