import React, { useState, createContext } from 'react'
import { Snackbar, Alert } from '@mui/material'

export const SnackbarContext = createContext({})

export const SnackbarProvider: React.FC = ({ children }) => {
  const [snack, setSnack] = useState<any>({
    message: '',
    color: 'success',
    open: false,
    close: true
  })
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setSnack({ open: false })
  }
  return (
    <>
      <Snackbar
        open={snack.open}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Alert onClose={handleClose} severity={snack.color} sx={{backgroundColor:'#fff !important'}}>
          {snack.message}
        </Alert>
      </Snackbar>

      <SnackbarContext.Provider value={{ snack, setSnack, handleClose }}>
        {children}
      </SnackbarContext.Provider>
    </>
  )
}
