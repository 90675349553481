import {
  Grid,
  Typography,
  useMediaQuery,
  Paper,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useAuth } from "../../../context/UserContext";
import React, { FC, useContext, useEffect } from "react";
import MuiTextField from "../../../components/MuiTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { Loading } from "../../../components/icons/Loading";
import ChangePassword from "./ChangePassword";
import { getUserDetails } from "../../../helpers/util";
import { Query, AuthenticateResponse } from "../../../api/fetch-client";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  section: {
    "& .fullWidth": {
      maxWidth: "100% !important",
      minWidth: "100%",
      width: "100% !important",
    },
  },
}));
interface Props {}
interface ITheme {
  _theme?: {
    breakpoints: any;
  };
  breakpoints?: any;
}
interface FormFields {
  id?: number;
  userLoggedId?: number;
  idVerified?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
}
type ValuesSchema = {
  email?: any;
  firstName?: any;
  lastName?: any;
  userName?: any;
};
const Profile: FC<Props> = () => {
  const { UpdateUser } = useAuth();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const { setSnack } = useContext<any>(SnackbarContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const classes = useStyles();
  const matches = useMediaQuery(
    (_theme: ITheme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const user = getUserDetails();
  useEffect(() => {
    setFieldValue("firstName", user?.firstName ? user?.firstName : "");
    setFieldValue("lastName", user?.lastName ? user?.lastName : "");
    setFieldValue("email", user?.email ? user?.email : "");
    setFieldValue("userName", user?.userName ? user?.userName : "");
    return () => {};
  }, []);
  const validationSchema = yup.object<ValuesSchema>({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    firstName: yup
      .string()
      .min(3, "First Name must be at least 3 characters long")
      .required("First Name is required"),
    lastName: yup
      .string()
      .min(3, "Last Name must be at least 3 characters long")
      .required("Last Name is required"),
    userName: yup
      .string()
      .min(3, "User Name must be at least 3 characters long")
      .required("User Name is required"),
  });
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
  } = useFormik<FormFields>({
    initialValues: {
      id: 0,
      userLoggedId: 1,
      idVerified: false,
      firstName: "",
      lastName: "",
      email: "",
      userName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = values;
      console.log(data);
      setIsLoading(true);
      Query.Client.accountUpdate(user?.id as number, data as any).then(
        (response: any) => {
          if (response.success) {
            let responseDate: AuthenticateResponse = response && response.data;
            UpdateUser(responseDate);
            setSnack({
              message: "User successfully saved",
              open: true,
              color: "success",
            });
          } else {
            let statuscode = t(response.statusCode);
            setSnack({
              message: statuscode,
              open: true,
              color: "error",
            });
          }
          setIsLoading(false);
        }
      );
    },
  });
  return (
    <>
      <Box sx={{ pt: 10, pb: 10 }}>
        <Dialog
          maxWidth={"lg"}
          open={openDialog}
          onClose={handleDialogClose}
          PaperProps={{
            sx: { minWidth: matches ? "100%" : "500px", width: "500px" },
          }}
        >
          <DialogContent sx={{ maxWidth: "590px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ChangePassword handleClose={handleDialogClose} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        <Paper sx={{ borderRadius: "15px", boxShadow: "none" }}>
          <Box
            borderColor="#D7DEEC"
            sx={{
              background: "#FFFFFF",
              border: "1px solid #FFFFFF",
              boxSizing: "borderBox",
            }}
          >
            <Box sx={{ m: matches ? 3 : 10 }}>
              <form onSubmit={handleSubmit} id="UserAddEditForm">
                <Grid
                  container
                  spacing={8}
                  className={matches ? classes.section : " "}
                  sx={{ mb: 2 }}
                >
                  <Grid item xs={4} className="fullWidth" sx={{ pb: 2 }}>
                    <Typography> First Name*</Typography>
                    <MuiTextField
                      name="firstName"
                      id="firstName"
                      placeholder={"Austin"}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>

                  <Grid item xs={4} className="fullWidth" sx={{ pb: 2 }}>
                    <Typography>{"Last Name *"}</Typography>
                    <MuiTextField
                      id="lastName"
                      placeholder={"Robinson"}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={8}
                  className={matches ? classes.section : " "}
                  sx={{ mb: 6 }}
                >
                  <Grid item xs={4} className="fullWidth" sx={{ pb: 2 }}>
                    <Typography>{"Email*"}</Typography>
                    <MuiTextField
                      id="email"
                      name="email"
                      placeholder="ex. jhon@example.com"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={4} className="fullWidth" sx={{ pb: 2 }}>
                    <Typography> User Name*</Typography>
                    <MuiTextField
                      name="userName"
                      id="userName"
                      placeholder={"User Name"}
                      value={values.userName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.userName && Boolean(errors.userName)}
                      helperText={touched.userName && errors.userName}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={8}
                  className={matches ? classes.section : " "}
                >
                  <Grid item xs={12} md={4} lg={4}>
                    {!isLoading ? (
                      <Button
                        sx={{
                          bgcolor: "secondary.main",
                          textTransform: "capitalize",
                          color: "#fff",

                          ":hover": {
                            bgcolor: "primary.dark",
                            color: "white",
                          },
                        }}
                        type="submit"
                      >
                        Save
                      </Button>
                    ) : (
                      <Loading align={'left'} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Typography
                      color="primary"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        textAlign: "right",
                      }}
                      onClick={handleDialogOpen}
                    >
                      Change Password
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default Profile;
