// import { parse } from 'date-fns';
// interface IUserDetails {
//   .expires?: string
// .issued?: string
// access_token?: string
// userData?: string
// userName?: string
// }

import { AuthenticateResponse } from "../api/fetch-client"

export const isSignin = () => {
  const token = localStorage.getItem('@App:token')
  return Boolean(token)
}
export const getToken = () => {
  return localStorage.getItem('@App:token')
}
export const setToken = (token: string) => {
  return localStorage.setItem('@App:token', token)
}
export const Logout = () => {
  localStorage.removeItem('@App:token')
  localStorage.removeItem('@App:user')
  localStorage.removeItem('@App:source')
}
export const getSource = () => {
  const source = localStorage.getItem('@App:source')
  return source
}
export const getUserDetails = () => {
  const storagedUser: string |null= localStorage.getItem('@App:user')
  const storagedUserObj: AuthenticateResponse = JSON.parse(storagedUser as string)
  return storagedUserObj
}

export const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i
export const allowedProfileExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
