

export enum paths  {
  DEFAULT= '/',
  ADMIN_SIGNIN= '/admin/signin',
  ADMIN_FILES= '/files',
  ADMIN_PROFILE= '/profile',
  ADMIN_RECOVER= '/recover',
  ADMIN_SET_PASSWORD= '/reset-password?token=',

  ADMIN_USERLIST= '/admin/users_list',

  USER_FILES= '/files',
  USER_PROFILE= '/profile',
  USER_SIGNIN= '/signin',
  USER_RECOVER= '/recover',
  USER_SET_PASSWORD= '/reset-password',

}
export default paths;
