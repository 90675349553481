import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const HtmlIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 500"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M349.985 500H49.9978C36.7376 500 24.0204 494.732 14.644 485.356C5.26761 475.98 0 463.262 0 450.002V50.0198C0 36.7596 5.26761 24.0424 14.644 14.666C24.0204 5.28961 36.7376 0.0219971 49.9978 0.0219971H224.99C225.214 -0.00733238 225.441 -0.00733238 225.665 0.0219971H225.815C226.051 0.0957858 226.294 0.146029 226.54 0.171992C228.74 0.321985 230.915 0.746963 233.015 1.44693H234.065C234.526 1.76989 234.961 2.12936 235.365 2.52188C238.088 3.7329 240.569 5.4268 242.689 7.52167L392.683 157.515C394.778 159.635 396.472 162.116 397.683 164.84C397.9 165.38 398.092 165.931 398.257 166.49L398.507 167.19C399.207 169.265 399.607 171.439 399.732 173.639C399.754 173.888 399.813 174.133 399.907 174.364V174.514C399.947 174.678 399.973 174.846 399.982 175.014V450.002C399.982 463.262 394.715 475.98 385.338 485.356C375.962 494.732 363.245 500 349.985 500ZM306.512 325.008V425.003H362.484V408.254H326.836V325.008H306.512ZM271.513 356.456L269.663 397.93V425.003H290.037V325.008H263.613L244.689 397.355L225.69 325.008H199.166V425.003H219.49V397.93L217.49 356.256L237.49 425.003H251.339L271.513 356.456ZM117.795 325.008V341.832H142.244V425.003H162.618V341.832H187.492V325.008H117.795ZM57.7975 382.23H87.7961V425.003H108.045V325.008H87.7961V365.456H57.7975V325.008H37.4983V425.003H57.7975V382.23ZM224.99 50.0198V175.014H349.985L224.99 50.0198Z"
        fill={fill}
      />
    </svg>
  );
};

export default HtmlIcon;
