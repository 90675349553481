import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const DocIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 500"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M204.65 313.799C189.225 313.799 180.225 328.474 180.225 348.124C180.225 367.899 189.5 381.874 204.8 381.874C220.225 381.874 229.075 367.174 229.075 347.524C229.075 329.374 220.375 313.799 204.65 313.799Z"
        fill={fill}
      />
      <path
        d="M250 0H50C36.7392 0 24.0215 5.26784 14.6447 14.6447C5.26784 24.0215 0 36.7392 0 50V450C0 463.261 5.26784 475.979 14.6447 485.355C24.0215 494.732 36.7392 500 50 500H350C363.261 500 375.979 494.732 385.355 485.355C394.732 475.979 400 463.261 400 450V150L250 0ZM128.875 386.35C118.225 395.2 102.05 399.375 82.275 399.375C70.4 399.375 62.025 398.625 56.325 397.875V298.6C66.5493 297.128 76.8706 296.434 87.2 296.525C106.4 296.525 118.85 299.975 128.575 307.325C139.075 315.125 145.675 327.575 145.675 345.375C145.675 364.75 138.625 378.1 128.875 386.35ZM203.625 400C173.625 400 156.1 377.35 156.1 348.55C156.1 318.275 175.425 295.65 205.275 295.65C236.3 295.65 253.25 318.875 253.25 346.775C253.225 379.9 233.125 400 203.625 400ZM320 380.95C326.875 380.95 334.525 379.425 339.05 377.65L342.5 395.475C338.3 397.575 328.85 399.825 316.575 399.825C281.65 399.825 263.65 378.1 263.65 349.3C263.65 314.825 288.225 295.65 318.825 295.65C330.675 295.65 339.65 298.05 343.7 300.15L339.05 318.3C332.963 315.785 326.436 314.51 319.85 314.55C301.7 314.55 287.6 325.5 287.6 348C287.6 368.225 299.6 380.95 320 380.95ZM250 175H225V50L350 175H250Z"
        fill={fill}
      />
      <path
        d="M89.6004 314.075C84.5254 314.075 81.2254 314.525 79.2754 314.975V381.1C81.2254 381.55 84.3754 381.55 87.2004 381.55C107.9 381.7 121.375 370.325 121.375 346.175C121.525 325.175 109.25 314.075 89.6004 314.075Z"
        fill={fill}
      />
    </svg>
  );
};

export default DocIcon;
