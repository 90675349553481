import React, { FC } from 'react'
interface Props {
  width?: number
  height?: number
  fill?: string
}
const FolderIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 28 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.230989 23.1703C0.491377 23.5623 0.929557 23.7989 1.39993 23.7989H22.399C22.9589 23.7989 23.4657 23.4657 23.6855 22.9505L27.8853 13.151C27.9773 12.9381 28.0149 12.7057 27.9947 12.4746C27.9744 12.2436 27.897 12.0212 27.7693 11.8276C27.6417 11.634 27.4678 11.4752 27.2635 11.3655C27.0591 11.2559 26.8307 11.1988 26.5988 11.1995H25.1988V5.59974C25.1988 4.05561 23.9431 2.79987 22.399 2.79987H13.8594L10.6619 0.298186C10.416 0.104983 10.1123 -2.62206e-05 9.79954 4.91106e-09H2.79987C1.25574 4.91106e-09 0 1.25574 0 2.79987V22.399H0.0097995C0.0066792 22.6722 0.0835684 22.9403 0.230989 23.1703ZM21.4764 20.999H3.52364L6.5237 13.9993H24.4765L21.4764 20.999ZM22.399 5.59974V11.1995H5.59974C5.03977 11.1995 4.53299 11.5327 4.3132 12.0478L2.79987 15.5799V5.59974H22.399Z'
        fill={fill}
      />
    </svg>
  )
}

export default FolderIcon
