import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { FC, useState, useContext } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import MuiTextField from '../../../components/MuiTextField'
import { Grid, Box, IconButton, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Loading } from '../../../components/icons/Loading'
import Logo from '../../../components/logo/logo.png'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { useHistory } from 'react-router-dom'
import { paths } from '../../../routing/paths'
import { SnackbarContext } from '../../../context/SnackbarContext'
import { makeStyles } from '@mui/styles'
import { ForgotPasswordRequest, IForgotPasswordRequest, Query } from '../../../api/fetch-client'

const useStyles = makeStyles<any>(theme => ({
  checkIcon: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    '& .MuiSvgIcon-root': {
      fontSize: '4rem',
      color: '#fff'
    }
  },
  closeIcon: {
    display: 'block',
    float: 'right',
    '& .MuiSvgIcon-root': {
      fontSize: '2.4rem'
    }
  }
}))
interface Props {
  _theme?: {
    breakpoints: any
  }
  breakpoints?: any
}
type ValuesSchema = {
  email?: any
}
const Recover: FC = () => {
  const matches = useMediaQuery(
    (_theme: Props) => _theme?.breakpoints?.down('sm') ?? '600'
  )
  const classes = useStyles()
  const { setSnack } = useContext<any>(SnackbarContext)
  const [recoverPassword, setRecoverPassword] = React.useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()

  const onClickNavigation = () => {
    history.push(paths.ADMIN_SIGNIN)
  }
  const validationSchema = yup.object<ValuesSchema>({
    email: yup
      .string()
      .email('Email is invalid')
      .required('Email is required')
  })
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors
  } = useFormik<IForgotPasswordRequest>({
    initialValues: {
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      RecoverPassword(values as ForgotPasswordRequest)
    }
  })
  function RecoverPassword (values: ForgotPasswordRequest) {
    setIsLoading(true)
Query.Client
      .forgotPassword(values)
      .then((response: any) => {
        console.log(response)
        if (response.success) {
          setRecoverPassword(false)
        } else {
          let statuscode: string = response.data.message
          setSnack({ message: statuscode, open: true, color: 'error' })
        }
        setIsLoading(false)
      })
  }

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          position: 'relative',
          bgcolor: 'primary.main'
        }}
      >
        <Card
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: matches ? '100%' : '400px',
            padding: 40,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            borderRadius: '10px',
            overflow: 'hidden'
          }}
        >
          {recoverPassword ? (
            <CardContent
              sx={{
                padding: '60px 30px 60px 30px',
                paddingBottom: '70px !important'
              }}
            >
              <form autoComplete='off' onSubmit={handleSubmit}>
                <>
                  <Grid
                    container
                    spacing={4}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Box
                        sx={{
                          height: '129px',
                          width: '160px'
                        }}
                      >
                        <img
                          width={'100%'}
                          height={'100%'}
                          src={Logo}
                          alt='logo'
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <MuiTextField
                        id='Email'
                        name='email'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={'E-mail'}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      justifyContent='center'
                      alignItems='center'
                    >
                      {!isLoading ? (
                        <>
                          <Button
                            variant='contained'
                            size='large'
                            color='secondary'
                            type='submit'
                            sx={{
                              minWidth: '100%',
                              textTransform: 'capitalize'
                            }}
                          >
                            Recover Password
                          </Button>
                        </>
                      ) : (
                        <Loading align={'center'}  />
                      )}
                    </Grid>
                  </Grid>
                </>
              </form>
            </CardContent>
          ) : (
            <CardContent
              sx={{
                padding: '0px'
              }}
            >
              <IconButton
                onClick={onClickNavigation}
                disableRipple
                className={classes.closeIcon}
              >
                <CancelOutlinedIcon />
              </IconButton>
              <Box sx={{ margin: '70px 0px 40px 0px', textAlign: 'center' }}>
                <IconButton disableRipple className={classes.checkIcon}>
                  <CheckOutlinedIcon />
                </IconButton>

                <Typography variant='h5' sx={{ mb: 3, mt: 3 }}>
                  <b>Verify your Email</b>
                </Typography>

                <Typography variant='subtitle2' sx={{ mb: 3, mt: 3 }}>
                  We have sent an email to {values?.email}
                </Typography>
                <Typography variant='subtitle2'>
                  if you have not received the verification email, please check
                  your "spam" or "Bulk Email" folder. you can aslo resend button
                  below to have another email sent to you.
                </Typography>

                {!isLoading ? (
                  <Button
                    variant='contained'
                    size='large'
                    color='secondary'
                    type='submit'
                    sx={{
                      minWidth: '100%',
                      mt: 5,
                      textTransform: 'capitalize'
                    }}
                    onClick={()=> RecoverPassword(values as ForgotPasswordRequest)}
                  >
                    Resend Verification Email
                  </Button>
                ) : (
                  <Loading align={'center'} />
                )}
              </Box>
            </CardContent>
          )}
        </Card>
      </Box>
    </>
  )
}

export default Recover
