import React, { FC, useContext, useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  Paper,
  Box,
  Button,
  IconButton,
  Typography,
  Menu,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { getUserDetails } from "../../../helpers/util";
import CloseIcon from "@mui/icons-material/Close";
import DevConfirmDialog from "../../../components/dialog/DevConfirmDialog";
import MuiTextField from "../../../components/MuiTextField";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "../../../components/icons/DownloadIcon";
import EditIcon from "../../../components/icons/EditIcon";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { useTheme } from "@mui/styles";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import { Loading } from "../../../components/icons/Loading";
import AddFolder from "./AddFolder";
import AddFile from "./AddFile";
import HomeIcon from "@mui/icons-material/Home";
import {
  ApiException,
  EnityStatusModel,
  FilePaginationItem,
  FolderPathModelIEnumerableResponse,
  FileFolderItemPaginationResultResponse,
  IFolderPaginationModel,
  FolderPaginationModel,
  Query,
  FileFolderItem,
} from "../../../api/fetch-client";
import { SnackbarContext } from "../../../context/SnackbarContext";
import FolderMove from "../../../components/FolderMove";
import AllFolder from "../../../components/AllFolder";
import AllFiles from "../../../components/AllFiles";
interface ITheme {
  history: string;
  _theme?: {
    breakpoints: any;
  };
  breakpoints?: any;
}

const Files: FC = () => {
  const [pathnames, setPathnames] = useState<any>([]);
  const { setSnack } = useContext<any>(SnackbarContext);
  const user = getUserDetails();
  const theme = useTheme<any>();
  const [foldersFiles, setFoldersFiles] = useState<FilePaginationItem[] | []>(
    []
  );
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [term, setTerm] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [infiniteLoading, setInfiniteLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedFolderFile] = useState<
    FilePaginationItem | undefined
  >();
  const [currentFolderId, setCurrentFolderId] = useState<number>(0);
  const [selectedFolderFile, setSelectedObject] = useState<
    FilePaginationItem | undefined
  >();
  const deleteStatus: number = 2;
  const [confirmDialog, setConfirmDialog] = useState<any>({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const matches = useMediaQuery(
    (_theme: ITheme) => _theme?.breakpoints?.down("sm") ?? "600"
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [anchorElFolder, setAnchorElFolder] =
    React.useState<null | HTMLElement>(null);
  const openFolder = Boolean(anchorElFolder);
  const [selectedMenuId, setSelectedMenuId] = React.useState<number>();

  const handleClick = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setSelectedFolderFile(item);
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSelectedFolderFile(undefined);
    setAnchorEl(null);
  };
  const handleDoubleClick = (
    event: React.MouseEvent<HTMLElement>,
    item: any
  ) => {
    switch (event.detail) {
      case 1:
        setSelectedFolderFile(item);
        break;
      case 2:
        setSelectedFolderFile(undefined);
        setCurrentFolderId(item.id);
        setPage(1);
        break;
    }
  };

  const handleFolderClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorElFolder(event.currentTarget);
  };
  const handleMoveFolderClose = () => {
    setAnchorElFolder(null);
    setSelectedMenuId(0);
    setAnchorEl(null);
    loadData(selectedItem?.parentId);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    //setSelectedFolderFile(undefined);
    // selectedItem && loadData(selectedItem?.id);
    // !selectedItem && loadData(selectedFolderFile?.parentId);
  };

  const scroll = (update: any) => {
    const section: any = document.querySelector("#true");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
    setTimeout(() => newlyUploadedFileRemoveEffect(update), 2000);
  };
  const newlyUploadedFileRemoveEffect = (update: any) => {
    console.log(update);
    let updatedFoldersFiles = update.map((item: any) => {
      if (item.scroll === "true") {
        return {
          ...item,
          scroll: "",
        };
      } else {
        return { ...item };
      }
    });
    setFoldersFiles(updatedFoldersFiles);
  };

  const handelEdit = (id: number | undefined, selectedItem: any) => {
    setOpenDialog(true);
    setSelectedObject(selectedItem);
    handleClose();
  };
  const handelAdd = () => {
    setSelectedObject(undefined);
    setOpenDialog(true);
  };
  const handleDelete = async (statusType: number) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let data = { id: selectedItem?.id, status: deleteStatus };
    //Folder
    if (selectedItem && selectedItem.type == 1) {
      await Query.Client.setFolderStatus(data as EnityStatusModel);
      let updatedFoldersFiles = foldersFiles.filter(
        (item) => item?.id !== selectedItem?.id
      );
      setFoldersFiles(updatedFoldersFiles);
      setSnack({
        message: "Folder successfully deleted",
        open: true,
        color: "success",
      });
    }
    //File
    else if (selectedItem && selectedItem.type == 0) {
      await Query.Client.setFileStatus(data as EnityStatusModel);
      let updatedFoldersFiles = foldersFiles.filter(
        (item) => item?.id !== selectedItem?.id
      );
      setFoldersFiles(updatedFoldersFiles);
      setSnack({
        message: "File successfully deleted",
        open: true,
        color: "success",
      });
    }
    handleClose();
  };
  const getDataFromURL = (url: string) => {
    handleClose();
    const filename = url.split("/").pop();
    return fetch(url, {
      headers: new Headers({
        Accept: "application/octet-stream",
      }),
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a: any = document.createElement("a");
        a.href = url;
        a.download = selectedItem?.name ? selectedItem?.name : filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  });

  const observer: React.MutableRefObject<any> = React.useRef();
  const lastElementRef = React.useCallback(
    (node) => {
      if (infiniteLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [infiniteLoading, hasMore]
  );

  useEffect(() => {
    loadData(currentFolderId);
  }, [page, currentFolderId, term]);

  const loadData = async (id: number | undefined) => {
    await Query.Client.getFolderPath(id)
      .then((response: FolderPathModelIEnumerableResponse) => {
        if (response.success) {
          setPathnames(response.data);
        }
      })
      .catch((error: ApiException) => {
        setSnack({ message: error.message, open: true, color: "error" });
        setIsLoading(false);
      });
    if (page <= 1) setFoldersFiles([]);
    page === 1 && setIsLoading(true);
    setInfiniteLoading(true);
    if (id == 0) {
      id = undefined;
    }
    var raw: IFolderPaginationModel = {
      page: page,
      takeCount: 10,
      id: id,
      search: term,
    };
    await Query.Client.getPage2(raw as FolderPaginationModel)
      .then((response: FileFolderItemPaginationResultResponse) => {
        if (response.success) {
          let responseData: any = response.data?.data;
          if (
            responseData !== null &&
            responseData !== [] &&
            responseData !== undefined
          ) {
            setFoldersFiles((nums) => {
              let data: any = new Set<FileFolderItem>([
                ...(nums as FileFolderItem[] | []),
                ...(responseData.map((o: any) => o) as FileFolderItem[] | []),
              ]);
              return [...data];
            });
          }
          setHasMore(
            (response.data?.totalPages ? response.data?.totalPages : 0) >
              (response.data?.currentPage ? response.data?.currentPage : 0)
          );
        }
        page === 1 && setIsLoading(false);
        setInfiniteLoading(false);
      })
      .catch((error: ApiException) => {
        setSnack({ message: error.message, open: true, color: "error" });
        page === 1 && setIsLoading(false);
        setInfiniteLoading(false);
      });

    page === 1 && setIsLoading(false);
    setInfiniteLoading(false);
  };
  useEffect(() => {
    setPage(1);
  }, [term]);
  let folders: FilePaginationItem[] | [] =
    foldersFiles && foldersFiles?.filter((x) => x.type === 1);
  let files: FilePaginationItem[] | [] =
    foldersFiles && foldersFiles?.filter((x) => x.type === 0);

  return (
    <>
      <Grid container spacing={6} sx={{ pt: 6, pb: 6 }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  onClick={() => setCurrentFolderId(0)}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Link>
                {pathnames?.map((item: any, index: number) => {
                  const last = index === pathnames.length - 1;

                  return last ? (
                    <Typography color={theme.palette.primary.main} key={index}>
                      {item.name}
                    </Typography>
                  ) : (
                    <Link
                      underline="hover"
                      color="inherit"
                      onClick={() => setCurrentFolderId(item.id)}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <MuiTextField
                placeholder={"Search"}
                search={true}
                id="search"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    setTerm(search);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1}>
              <Button
                sx={{
                  bgcolor: "primary.main",
                  textTransform: "capitalize",
                  color: "#fff",
                  width: "100%",
                  ":hover": {
                    bgcolor: "primary.dark",
                    color: "white",
                  },
                }}
                onClick={() => setTerm(search)}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1}>
              <Button
                sx={{
                  width: "100%",
                  background: "#FFF",
                  border: "1px solid #F1F1F5",
                  textTransform: "capitalize",
                }}
                onClick={(e) => {
                  setSearch("");
                  setTerm("");
                }}
                variant="outlined"
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={1} sm={1} md={1}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              sx={{ textAlign: "right" }}
            >
              {user.role !== "1" && (
                <>
                  <Button
                    sx={{
                      bgcolor: "primary.main",
                      textTransform: "capitalize",
                      color: "#fff",
                      marginRight: "10px",
                      ":hover": {
                        bgcolor: "primary.dark",
                        color: "white",
                      },
                    }}
                    onClick={() => handelAdd()}
                  >
                    <AddIcon /> Add New Folder
                  </Button>
                  <AddFile
                    type={"uploadButton"}
                    handleClose={handleDialogClose}
                    currentFolderId={currentFolderId}
                    setFoldersFiles={setFoldersFiles}
                    foldersFiles={foldersFiles}
                    scroll={scroll}
                  />
                </>
              )}
            </Grid>
            <Dialog
              maxWidth={"lg"}
              open={openDialog}
              onClose={handleDialogClose}
              PaperProps={{
                sx: { minWidth: matches ? "100%" : "500px", width: "500px" },
              }}
            >
              <DialogContent sx={{ maxWidth: "590px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h5" sx={{ pb: 6 }}>
                      <b>
                        {selectedFolderFile &&
                        (selectedFolderFile?.id as number) > 0
                          ? "Rename"
                          : "Add New Folder"}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      style={{ float: "right" }}
                      onClick={handleDialogClose}
                    >
                      <IconButton>
                        <CloseIcon />
                      </IconButton>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <AddFolder
                      handleClose={handleDialogClose}
                      selectedFolderFile={
                        selectedFolderFile ? selectedFolderFile : undefined
                      }
                      currentFolderId={currentFolderId}
                      setFoldersFiles={setFoldersFiles}
                      foldersFiles={foldersFiles}
                      scroll={scroll}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ borderRadius: "15px", boxShadow: "none" }}>
            <Box
              borderColor="#D7DEEC"
              sx={{
                background: "#FFFFFF",
                border: "1px solid #FFFFFF",
                boxSizing: "borderBox",
              }}
              id="contextmenu"
            >
              <Box sx={{ m: 5 }}>
                {/* Folders start  */}
                <AllFolder
                  isLoading={isLoading}
                  folders={folders}
                  handleClick={handleClick}
                  handleDoubleClick={handleDoubleClick}
                  selectedItem={selectedItem}
                  lastElementRef={lastElementRef}
                />
                {/* Folders end  */}

                {/* Files start  */}
                <AllFiles
                  isLoading={isLoading}
                  files={files}
                  handleClick={handleClick}
                  handleDialogClose={handleDialogClose}
                  selectedItem={selectedItem}
                  currentFolderId={currentFolderId}
                  lastElementRef={lastElementRef}
                  setFoldersFiles={setFoldersFiles}
                  foldersFiles={foldersFiles}
                  scroll={scroll}
                />
                {/* Files end  */}
                {infiniteLoading && <Loading align={"center"} />}

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  // onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  {user.role !== "1" && (
                    <MenuItem
                      onClick={(e) => {
                        handelEdit(selectedItem?.id, selectedItem);
                      }}
                      disableRipple
                    >
                      <EditIcon /> Rename
                    </MenuItem>
                  )}
                  {selectedItem?.type === 0 && (
                    <MenuItem
                      disableRipple
                      onClick={(e) =>
                        getDataFromURL(selectedItem?.downloadUrl as string)
                      }
                    >
                      <DownloadIcon /> Download
                    </MenuItem>
                  )}
                  {selectedItem?.type === 1 && (
                    <MenuItem
                      disableRipple
                      onClick={(e) => {
                        handleFolderClick(e);
                      }}
                    >
                      <Box sx={{ mr: "12px" }}>
                        <DriveFileMoveOutlinedIcon sx={{ fontSize: "17px" }} />
                      </Box>{" "}
                      Move to
                    </MenuItem>
                  )}
                  {user.role !== "1" && (
                    <MenuItem
                      onClick={(e) => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to delete this record?",
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            handleDelete(deleteStatus);
                          },
                        });
                        handleClose();
                      }}
                      disableRipple
                    >
                      <DeleteIcon /> Delete
                    </MenuItem>
                  )}
                </Menu>

                {/* Move Folder Menu Start  */}
                <Menu
                  anchorEl={anchorElFolder}
                  id="account-menu"
                  open={openFolder}
                  onClose={handleMoveFolderClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      ".MuiMenu-list": {
                        paddingTop: "0px ",
                        paddingBottom: "0px",
                      },
                      width: "350px",
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                      "& .Mui-selected:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFF",
                      },
                      "& 	.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFF",
                      },
                    },
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                >
                  <FolderMove
                    currentFolder={selectedItem}
                    handelclose={handleMoveFolderClose}
                    selectedItem={selectedItem}
                  />
                </Menu>
                {/* Move Folder Menu End  */}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <DevConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Files;
