import React, { FC } from 'react'
interface Props {
  fill?: any
  width?: number
  height?: number
}
const TextFileIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 62 78'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M38.75 0H7.75C5.69457 0 3.72333 0.816515 2.26992 2.26992C0.816515 3.72333 0 5.69457 0 7.75V69.75C0 71.8054 0.816515 73.7767 2.26992 75.2301C3.72333 76.6835 5.69457 77.5 7.75 77.5H54.25C56.3054 77.5 58.2767 76.6835 59.7301 75.2301C61.1835 73.7767 62 71.8054 62 69.75V23.25L38.75 0ZM23.2423 49.476H18.9681V62.1628H15.4148V49.476H11.2104V46.5H23.2423V49.476ZM33.8016 62.1628L32.3872 59.3301C31.806 58.2374 31.434 57.4236 30.9961 56.5169H30.9458C30.6241 57.4197 30.2289 58.2335 29.7367 59.3301L28.4386 62.1628H24.3892L28.9269 54.2384L24.552 46.5H28.6207L29.9925 49.3597C30.4614 50.3091 30.8062 51.0764 31.1782 51.9599H31.2286C31.6006 50.9601 31.9029 50.2626 32.2981 49.3597L33.6195 46.5H37.6611L33.2475 54.1454L37.8897 62.1628H33.8016ZM50.7896 49.476H46.5116V62.1628H42.9583V49.476H38.7539V46.5H50.7858V49.476H50.7896ZM38.75 27.125H34.875V7.75L54.25 27.125H38.75Z'
        // fill='#C8C8C8'
        fill={fill}
      />
    </svg>
  )
}

export default TextFileIcon
