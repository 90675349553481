import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import MainRouter from './routing/MainRouter'
import { AuthProvider } from "./context/UserContext";
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { SnackbarProvider } from './context/SnackbarContext'
function App () {
  return (
    <>
      <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <MainRouter />
        </SnackbarProvider>
        </AuthProvider>
      </ThemeProvider>
    </>
  )
}

export default App
