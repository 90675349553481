import React, { FC, useEffect, useState, useContext } from "react";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import MuiTextField from "../../../components/MuiTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { PageLoading } from "../../../components/icons/PageLoading";
import { makeStyles } from "@mui/styles";
import { Loading } from "../../../components/icons/Loading";
import {
  AccountResponse,
  AccountResponseResponse,
  CreateRequest,
  ICreateRequest,
  Query,
  Role,
  UpdateRequest,
  UserPaginationItem
} from "../../../api/fetch-client";
import { useTranslation } from "react-i18next";

var _ = require("lodash");
const useStyles = makeStyles((theme) => ({
  section: {
    "& .fullWidth": {
      maxWidth: "100% !important",
      minWidth: "100%",
      width: "100% !important",
    },
  },
}));
interface Props {
  id?: any;
  handleClose?: any;
  setRecords?:any;
  records?:any
}
interface ITheme {
  history: string;
  _theme?: {
    breakpoints: any;
  };
  breakpoints?: any;
}
interface FormFields {
  id?: number;
  userLoggedId?: number;
  idVerified?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  password?: string;
  role?: string;
}
type ValuesSchema = {
  email?: any;
  firstName?: any;
  lastName?: any;
  userName?: any;
  password?: any;
  role?: any;
};
const AddEditUser: FC<Props> = ({ id, handleClose, setRecords,records }) => {
  const { setSnack } = useContext<any>(SnackbarContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPageLoading, setPageLoading] = useState<boolean>(false);
  const [user, setUser] = useState<AccountResponse | undefined>();
  const matches = useMediaQuery(
    (_theme: ITheme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const { t } = useTranslation();

  useEffect(() => {
    loadData(id);
    return () => {};
  }, [id]);

  const loadData = (id: number) => {
    setPageLoading(true);
    Query.Client.accountGET(id).then((response: AccountResponseResponse) => {
      if (response) {
        setUser(response.data);
      } else {
        id !== 0 &&
          setSnack({
            message: "Could not get user details",
            open: true,
            color: "error",
          });
      }
      setPageLoading(false);
    });
    setPageLoading(false);
  };
  useEffect(() => {
    setFieldValue("firstName", user?.firstName ? user?.firstName : "");
    setFieldValue("lastName", user?.lastName ? user?.lastName : "");
    setFieldValue("email", user?.email ? user?.email : "");
    setFieldValue("userName", user?.userName ? user?.userName : "");
    setFieldValue("role", user?.role ? user?.role : 0);
    let initialVlues = values;
    return () => {
      if (_.isEqual(initialVlues, values)) {
        console.log("same");
      } else console.log("diffrent");
    };
  }, [user]);

  const validationSchema = yup.object<ValuesSchema>({
    email: yup
      .string()
      .email(t("Pleaseenteravalidemail"))
      .required(t("Emailisrequired")),
    firstName: yup
      .string()
      .min(3, t("FirstNamemustbeatleast3characterslong"))
      .required(t("FirstNameisrequired")),
    lastName: yup
      .string()
      .min(3, t("LastNamemustbeatleast3characterslong"))
      .required(t("LastNameisrequired")),
    userName: yup
      .string()
      .min(3, t("UserNamemustbeatleast3characterslong"))
      .required(t("UserNameisrequired")),
    password:
      !id &&
      yup
        .string()
        .min(6, t("Passwordmustbeatleast6characters"))
        .required(t("Passwordisrequired")),
    role: yup.string().required(t("Roleisrequired")),
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
    setFieldValue,
  } = useFormik<ICreateRequest>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      userName: "",
      password: "",
      role: Role.User.toString(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = values;
      console.log(data);
      setIsLoading(true);
      if (id > 0) {
        updateUser(id, data as UpdateRequest);
      } else {
        addUser(data as CreateRequest);
      }
    },
  });
  const updateUser = (id: number, data: UpdateRequest) => {
    Query.Client.accountUpdate(id, data).then((response: any) => {
      if (response.success) {
        setSnack({
          message: "User successfully saved",
          open: true,
          color: "success",
        });
        let userData:AccountResponse | undefined = response.data
        let updatedRecord = records.map((item:any) => {
          if (id === item.id) {
            return {
              ...item,
              id:userData?.id,
              email: userData?.email,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              userName:userData?.userName,
              role: userData?.role
            };
          } else {
            return { ...item };
          }
        });
        const _updateRecord = [...(updatedRecord ?? [])];
        setRecords(_updateRecord);
        handleClose();
      } else {
        let statuscode = t(response.statusCode);
        setSnack({
          message: statuscode,
          open: true,
          color: "error",
        });
      }
    });
    setIsLoading(false);
  };
  const addUser = (data: CreateRequest) => {
    Query.Client.accountPOST(data).then((response: any) => {
      if (response.success) {
        setSnack({
          message: "User successfully saved",
          open: true,
          color: "success",
        });
        let userData:AccountResponse | undefined = response.data
        let updatedRecord = {
          id:userData?.id,
          email: userData?.email,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          userName:userData?.userName,
          role: userData?.role
        };
        const _updateRecord = [...(records ?? []), updatedRecord];
        setRecords(_updateRecord);
        handleClose();
      } else {
        let statuscode = t(response.statusCode);
        setSnack({
          message: statuscode,
          open: true,
          color: "error",
        });
      }
    });
    setIsLoading(false);
  };
  return (
    <>
      {!isPageLoading ? (
        <>
          <form onSubmit={handleSubmit} id="UserAddEditForm">
            <Grid
              container
              spacing={2}
              className={matches ? classes.section : " "}
            >
              <Grid item xs={12} className="fullWidth" sx={{ pb: 2 }}>
                <Typography> {t("FirstName")}*</Typography>
                <MuiTextField
                  name="firstName"
                  id="firstName"
                  placeholder={"Austin"}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>

              <Grid item xs={12} className="fullWidth" sx={{ pb: 2 }}>
                <Typography>{t("LastName")}*</Typography>
                <MuiTextField
                  id="lastName"
                  placeholder={"Robinson"}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>

              <Grid item xs={12} className="fullWidth" sx={{ pb: 2 }}>
                <Typography>{t("Email")}*</Typography>
                <MuiTextField
                  id="email"
                  name="email"
                  placeholder="ex. jhon@example.com"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} className="fullWidth" sx={{ pb: 2 }}>
                <Typography> {t("UserName")}*</Typography>
                <MuiTextField
                  name="userName"
                  id="userName"
                  placeholder={t("UserName")}
                  value={values.userName}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.userName && Boolean(errors.userName)}
                  helperText={touched.userName && errors.userName}
                />
              </Grid>
              <Grid item xs={12} className="fullWidth" sx={{ pb: 2 }}>
                <Typography> {t("Password")}*</Typography>

                <MuiTextField
                  id="Password"
                  type="password"
                  autoComplete="new-password"
                  isProtected="true"
                  name="password"
                  placeholder={t("Password")}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12} className="fullWidth" sx={{ pb: 2 }}>
                <Typography>{t("Role")}*</Typography>

                <RadioGroup
                  row
                  aria-label="role"
                  name="role"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.role}
                >
                  <FormControlLabel
                    value={Role.Admin.toString()}
                    control={<Radio />}
                    label="Admin"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={Role.User.toString()}
                    control={<Radio />}
                    label="User"
                    labelPlacement="end"
                  />
                </RadioGroup>
                <FormHelperText
                  error={true}
                  style={{ display: "inline-block", marginLeft: 15 }}
                >
                  {errors.role && touched.role && errors.role}
                </FormHelperText>
              </Grid>

              <Grid item xs={12} className="fullWidth">
                {!isLoading ? (
                  <Button
                    sx={{
                      bgcolor: "secondary.main",
                      textTransform: "capitalize",
                      color: "#fff",
                      minWidth: "100%",

                      ":hover": {
                        bgcolor: "primary.dark",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    {t("Save")}
                  </Button>
                ) : (
                  <Loading align={"center"} />
                )}
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default AddEditUser;
