import React, { FC } from "react";
import TextFileIcon from "./icons/TextFileIcon";
import ImageIcon from "./icons/ImageIcon";
import MediaIcon from "./icons/MediaIcon";
import DocumentIcon from "./icons/DocumentIcon";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AviIcon from "./icons/AviIcon";
import CsvIcon from "./icons/CsvIcon";
import DocIcon from "./icons/DocIcon";
import DocxIcon from "./icons/DocxIcon";
import HtmlIcon from "./icons/HtmlIcon";
import Mp3Icon from "./icons/Mp3Icon";
import XlsIcon from "./icons/XlsIcon";
import XlsxIcon from "./icons/XlsxIcon";

import FlaIcon from "./icons/FlaIcon";
import MpgIcon from "./icons/MpgIcon";
import RtfIcon from "./icons/RtfIcon";
import WmaIcon from "./icons/WmaIcon";
import ZipIcon from "./icons/ZipIcon";

import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import PptIcon from "./icons/PptIcon";
const useStyles = makeStyles<any>((theme) => ({
  iconBig: {
    color: "#C8C8C8",
    width: "62px",
    height: "78px",
  },
  iconSmall: {
    width: "20px",
    height: "18px",
    fill: theme.palette.primary.main,
  },
}));
interface Props {
  name?: string;
  extension?: string;
}
const DevFiles: FC<Props> = ({ name, extension }) => {
  const classes = useStyles();
  const theme = useTheme<any>();
  return (
    <>
      <>
        <Box
          sx={{
            textAlign: "center",
            padding: "15px 0px 15px 0px",
          }}
        >
          {extension === "zip" && (
            <>
              <ZipIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "wma" && (
            <>
              <WmaIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "rtf" && (
            <>
              <RtfIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "mpg" && (
            <>
              <MpgIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "fla" && (
            <>
              <FlaIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "xlsx" && (
            <>
              <XlsxIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "xls" && (
            <>
              <XlsIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "mp3" && (
            <>
              <Mp3Icon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "html" && (
            <>
              <HtmlIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "docx" && (
            <>
              <DocxIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "doc" && (
            <>
              <DocIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "csv" && (
            <>
              <CsvIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "avi" && (
            <>
              <AviIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "txt" && (
            <>
              <TextFileIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {(extension === "png" ||
            extension === "PNG" ||
            extension === "jpg" ||
            extension === "gif" ||
            extension === "jpeg") && (
            <>
              <ImageIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "mp4" && (
            <>
              <MediaIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "doc" && (
            <>
              <DocumentIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
          {extension === "pdf" && (
            <>
              <PictureAsPdfIcon className={classes.iconBig} />
            </>
          )}
          {(extension === "ppt" || extension === "pptx") && (
            <>
              <PptIcon fill={"#C8C8C8"} width={62} height={78} />
            </>
          )}
        </Box>
        <Box
          sx={{
            padding: "5px 15px 15px 15px",
            display: "flex",
          }}
        >
          <Typography sx={{ margin: "2px" }}>
            {extension === "zip" && (
              <>
                <ZipIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "wma" && (
              <>
                <WmaIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "rtf" && (
              <>
                <RtfIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "mpg" && (
              <>
                <MpgIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "fla" && (
              <>
                <FlaIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "xlsx" && (
              <>
                <XlsxIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "xls" && (
              <>
                <XlsIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "mp3" && (
              <>
                <Mp3Icon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
                -
              </>
            )}
            {extension === "html" && (
              <>
                <HtmlIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "docx" && (
              <>
                <DocxIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "doc" && (
              <>
                <DocIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "csv" && (
              <>
                <CsvIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "avi" && (
              <>
                <AviIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "txt" && (
              <>
                <TextFileIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {(extension === "png" ||
              extension === "PNG" ||
              extension === "jpg" ||
              extension === "jpeg") && (
              <>
                <ImageIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "mp4" && (
              <>
                <MediaIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "doc" && (
              <>
                <DocumentIcon
                  fill={theme.palette.primary.main}
                  width={20}
                  height={18}
                />
              </>
            )}
            {extension === "pdf" && (
              <>
                <PictureAsPdfIcon className={classes.iconSmall} />
              </>
            )}
          </Typography>

          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Typography>
        </Box>
      </>
    </>
  );
};

export default DevFiles;
