import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { FC, useState, useContext, useEffect } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import MuiTextField from '../../../components/MuiTextField'
import { Grid, Box, IconButton, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Loading } from '../../../components/icons/Loading'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { useHistory } from 'react-router-dom'
import { paths } from '../../../routing/paths'
import { makeStyles } from '@mui/styles'
// import { AuthService } from '../../../services/AuthServices'
import { SnackbarContext } from '../../../context/SnackbarContext'
import { Client, IResetPasswordRequest, Query, ResetPasswordRequest } from '../../../api/fetch-client'
import {useLocation} from "react-router-dom";


const useStyles = makeStyles<any>(theme => ({
  checkIcon: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    '& .MuiSvgIcon-root': {
      fontSize: '4rem',
      color: '#fff'
    }
  },
  closeIcon: {
    display: 'block',
    float: 'right',
    '& .MuiSvgIcon-root': {
      fontSize: '2.4rem'
    }
  }
}))

interface Props {
  _theme?: {
    breakpoints: any
  }
  breakpoints?: any
}

type ValuesSchema = {
  password?: any
  confirmPassword?: any
}
const SetPassword: FC = () => {
  const matches = useMediaQuery(
    (_theme: Props) => _theme?.breakpoints?.down('sm') ?? '600'
  )
  const classes = useStyles()
  const { setSnack } = useContext<any>(SnackbarContext)

  const [passwordSet, setNewPassword] = React.useState<boolean>(true)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const history = useHistory()
  
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const onClickNavigation = () => {
    history.push(paths.ADMIN_SIGNIN)
  }

  const validationSchema = yup.object<ValuesSchema>({
    password: yup
      .string()
      .required('New Password  is required')
      .min(6, 'New Password  must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'New Password and Confirm New Password must match')
      .required('Confirm New Password   is required')
  })
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors
  } = useFormik<IResetPasswordRequest>({
    initialValues: {
      token:'',
      password: '',
      confirmPassword: '',
      

    },
    validationSchema: validationSchema,
    onSubmit: values => {
      addNewPassword(values as ResetPasswordRequest)
    }
  })
  const  addNewPassword  =(data: ResetPasswordRequest) =>{
    setIsLoading(true)
    const currentURL: any = window.location.href

    // var token = new URL(currentURL).hash
    //   .split('?')
    //   .filter(function (el) {
    //     if (el.match('token') !== null) return true
    //   })[0]
    //   .split('=')[1]
      console.log(token);
      data.token = token as string
    Query.Client
      .resetPassword(data)
      .then((response: any) => {
        if (response.success) {
          setNewPassword(false)
        } else {
          let statuscode: any = response.data.data
          setSnack({ message: statuscode, open: true, color: 'error' })
        }
        setIsLoading(false)
      })
  }
  return (
    <>
      <Box
        sx={{
          height: '100vh',
          position: 'relative',
          bgcolor: 'primary.main'
        }}
      >
        <Card
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: matches ? '100%' : '400px',
            padding: 40,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            borderRadius: '10px',
            overflow: 'hidden'
          }}
        >
          {passwordSet ? (
            <CardContent
              sx={{
                padding: '60px 30px 60px 30px',
                paddingBottom: '70px !important'
              }}
            >
              <form autoComplete='off' onSubmit={handleSubmit}>
                <>
                  <Grid
                    container
                    spacing={4}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <MuiTextField
                        name='password'
                        type='password'
                        isProtected='true'
                        placeholder={'Enter New Password *'}
                        id='password'
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.password && Boolean(errors.password)
                        }
                        helperText={touched.password && errors.password}
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <MuiTextField
                        id='Password'
                        placeholder={'Confirm  Password**'}
                        name='confirmPassword'
                        type='password'
                        isProtected='true'
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.confirmPassword &&
                          Boolean(errors.confirmPassword)
                        }
                        helperText={
                          touched.confirmPassword &&
                          errors.confirmPassword
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      justifyContent='center'
                      alignItems='center'
                    >
                      {!isLoading ? (
                        <>
                          <Button
                            variant='contained'
                            size='large'
                            color='secondary'
                            type='submit'
                            sx={{
                              minWidth: '100%',
                              textTransform: 'capitalize'
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      ) : (
                        <Loading align={'center'} />
                      )}
                    </Grid>
                  </Grid>
                </>
              </form>
            </CardContent>
          ) : (
            <CardContent
              sx={{
                padding: '0px'
              }}
            >
              <Box sx={{ margin: '70px 0px 40px 0px', textAlign: 'center' }}>
                <IconButton disableRipple className={classes.checkIcon}>
                  <CheckOutlinedIcon />
                </IconButton>

                <Typography variant='h5' sx={{ mb: 3, mt: 3 }}>
                  <b>Password Updated!</b>
                </Typography>

                <Typography variant='subtitle2'>
                  Your password has been changed Successfully. Use your new
                  password to login.
                </Typography>

                <Button
                  onClick={onClickNavigation}
                  variant='contained'
                  size='large'
                  color='secondary'
                  type='submit'
                  sx={{
                    minWidth: '100%',
                    mt: 5,
                    textTransform: 'capitalize'
                  }}
                >
                  Login
                </Button>
              </Box>
            </CardContent>
          )}
        </Card>
      </Box>
    </>
  )
}

export default SetPassword
