import React, { FC, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Box,
  Button,
  IconButton,
  Typography,
  Menu,
  useMediaQuery,
  Drawer,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NoData } from "../../../components/icons/NoData";
import DevConfirmDialog from "../../../components/dialog/DevConfirmDialog";
import MuiTextField from "../../../components/MuiTextField";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import AddEditUser from "./AddEditUser";
import {
  EnityStatusModel,
  IEnityStatusModel,
  IUserPaginationModel,
  Query,
  Role,
  UserPaginationItem,
  UserPaginationItemPaginationResultResponse,
  UserPaginationModel,
} from "../../../api/fetch-client";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
const useStyles = makeStyles((theme) => ({
  topButton: {
    "& .fullWidth": {
      maxWidth: "100% !important",
      minWidth: "100%",
      width: "100% !important",
    },
  },

  grid: {
    color: "#333",
    background: "#FFFFFF",
    border: "0.5px solid #CCCCCC",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    "& .svgIcon": {
      color: "#333 ",
      "& .MuiSvgIcon-root": {
        color: "#333 ",
      },
    },
    "& .typoColor": {
      color: "#3C5F7B",
    },
    "& .typoLight": {
      color: "#9DA2AA",
    },
  },
  gridBg: {
    background: "#20ABE6",
    color: "#FFF",
    border: "0.5px solid #CCCCCC",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    "& .svgIcon": {
      color: "#FFF",
      "& .MuiSvgIcon-root": {
        color: "#FFF",
      },
    },
    "& .typoColor": {
      color: "#FFF",
    },
    "& .typoLight": {
      color: "#FFF",
    },
  },
}));

interface ITheme {
  history: string;
  _theme?: {
    breakpoints: any;
  };
  breakpoints?: any;
}
const UserList: FC = () => {
  const { t } = useTranslation();
  const [records, setRecords] = useState<UserPaginationItem[] | []>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [term, setTerm] = useState<any>("");
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [takeCount, setTakeCount] = useState<number>(12);
  const [page, setPage] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<
    UserPaginationItem | undefined
  >(undefined);
  const [selectedId, setSelectedId] = useState<number>(0);

  const deleteStatus: number = 2;
  const activeStatus: number = 0;
  const inActiveStatus: number = 1;

  const switchAdmin: number = 0;
  const adminMessage: string = "Are you sure you want to switch  to a Admin?";

  const switchUser: number = 1;
  const userMessage: string = "Are you sure you want to switch  to a User?";

  const [confirmDialog, setConfirmDialog] = useState<any>({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const matches = useMediaQuery(
    (_theme: ITheme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const matchesTab = useMediaQuery(
    (_theme: ITheme) => _theme?.breakpoints?.down("md") ?? "768px"
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
    console.log(item.id);
  };
  const handleClose = () => {
    setSelectedItem(undefined);
    setAnchorEl(null);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(undefined);
    // loadData(tabValue);
  };
  const handleChange = (event: any, value: number) => {
    setPage(value);
    setTakeCount(10);
  };

  const handleTabChange = (event: any, newValue: number) => {
    setTabValue(newValue);
    setPage(0);
  };
  const handelEdit = (id: number) => {
    setOpenDialog(true);
    setSelectedId(id);
    console.log(id);
  };
  const handelAdd = () => {
    setSelectedId(0);
    setOpenDialog(true);
  };
  const handleSetStatus = (statusType: number) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    var raw: IEnityStatusModel = {
      id: selectedItem?.id as number,
      status: statusType,
    };
    Query.Client.setStatus(raw as EnityStatusModel).then((response: any) => {
      let updatedRecords = records.filter((item) => item?.id !== selectedItem?.id);
      setRecords(updatedRecords)
    });

    handleClose();
  };

  const handleSwitch = (switchType: number) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    var raw = {
      id: selectedItem?.id as number,
      role: switchType,
    };
    Query.Client.updateRole(selectedItem?.id as number).then(
      (response: any) => {
        let updatedRecord = records.map((item:any) => {
          if (selectedItem?.id === item.id) {
            return {
              ...item,
              email: selectedItem?.email,
              firstName: selectedItem?.firstName,
              id: selectedItem?.id,
              lastName: selectedItem?.lastName,
              role: switchType
            };
          } else {
            return { ...item };
          }
        });
        const _updateRecord = [...(updatedRecord ?? [])];
        _updateRecord.sort((a, b) => a.role - b.role);
        setRecords(_updateRecord);
      }
    );
    handleClose();
  };

  const observer: React.MutableRefObject<any> = React.useRef();
  const lastRecordrElementRef = React.useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );
  useEffect(() => {
    loadData(tabValue);
  }, [page, tabValue, term]);
  useEffect(() => {
    setRecords([]);
    setPage(0)
  }, [tabValue]);
  const loadData = (activeIndex: number) => {
    if (page <= 0) setRecords([]);
    setIsLoading(true);
    var raw: IUserPaginationModel = {
      page: page ,
      takeCount: takeCount,
      activeIndex: activeIndex,
      name:term
    };
    Query.Client.getPage(raw as UserPaginationModel).then(
      (response: UserPaginationItemPaginationResultResponse) => {
        if (activeIndex === tabValue) {
          if (response.success) {
            let data = response?.data?.data;
            // setRecords(response?.data?.data);
            setRecords((nums) => {
              let data:any =  new Set<UserPaginationItem>([...nums, ...response.data?.data?.map((o) => o) as UserPaginationItem[]]);
                return [...data];
              });
            setHasMore(
              (response.data?.totalPages ? response.data?.totalPages : 0) - 1 >
                (response.data?.currentPage ? response.data?.currentPage : 0)
            );
            setTotalPages(response?.data?.totalPages as number);
            setIsLoading(false);
          }
        }
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    setPage(0);
  }, [term]);
  return (
    <>
      <Grid container spacing={6} sx={{ pt: 6, pb: 6 }}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            className={matches ? classes.topButton : " "}
          >
            <Grid item xs={12} sm={12} md={8} lg={5} >
              <MuiTextField
                placeholder={t("Search")}
                search={true}
                id="search"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    setTerm(search);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1} >
              <Button
                sx={{
                  bgcolor: "primary.main",
                  textTransform: "capitalize",
                  color: "#fff",
                  width: "100%",
                  ":hover": {
                    bgcolor: "primary.dark",
                    color: "white",
                  },
                }}
                onClick={() => setTerm(search)}
              >
                {t("Submit")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={1} >
              <Button
                sx={{
                  width: "100%",
                  background: "#FFF",
                  border: "1px solid #F1F1F5",
                  textTransform: "capitalize",
                }}
                onClick={(e) => {
                  setSearch("");
                  setTerm("");
                }}
                variant="outlined"
              >
                {t("Clear")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} >
              <Button
                sx={{
                  bgcolor: "primary.main",
                  textTransform: "capitalize",
                  color: "#fff",
                  float: "right",
                  ":hover": {
                    bgcolor: "primary.dark",
                    color: "white",
                  },
                }}
                onClick={handelAdd}
              >
                <AddIcon /> {t("AddNewUser")}
              </Button>
            </Grid>

            <Drawer
              open={openDialog}
              onClose={handleDialogClose}
              anchor="right"
              sx={{
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: matches ? "100%" : matchesTab ? '70%' : "30%",
                  boxSizing: "border-box",
                  bgcolor: "body",
                },
              }}
            >
              <CardContent
                className={matches ? classes.topButton : " "}
                sx={{ p: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="h5" sx={{ pb: 6 }}>
                      {" "}
                      <b>
                        {selectedId > 0 ? t("EditPerson") : t("AddNewUser")}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      style={{ float: "right" }}
                      onClick={handleDialogClose}
                    >
                      <IconButton>
                        <CloseIcon />
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AddEditUser
                      id={selectedId ? selectedId : 0}
                      handleClose={handleDialogClose}
                      records={records} 
                      setRecords={setRecords}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Drawer>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ borderRadius: "15px", boxShadow: "none" }}>
            <Box
              borderColor="#D7DEEC"
              sx={{
                background: "#FFFFFF",
                border: "1px solid #FFFFFF",
                boxSizing: "borderBox",
              }}
            >
              <Box sx={{ m: 1 }}>
                <Tabs
                  onChange={handleTabChange}
                  value={tabValue}
                  aria-label="Users Tabs"
                  selectionFollowsFocus
                >
                  <Tab
                    sx={{ letterSpacing: "0em", textTransform: "capitalize" }}
                    label={t("Active")}
                  />
                  <Tab
                    sx={{ letterSpacing: "0em", textTransform: "capitalize" }}
                    label={t("Inactive")}
                  />
                </Tabs>

                {/* {!isLoading ? (  
                  <> */}
                {(records?.length as number) > 0 ? (
                  <>
                    <Grid container spacing={6} sx={{ pt: 2 }}>
                      {records &&
                        records.map((item: UserPaginationItem, i: number) => {
                          if (records.length === i + 1) {
                            return (
                              <Grid
                                item
                                xs={12}
                                lg={3}
                                md={3}
                                key={i}
                                ref={lastRecordrElementRef}
                              >
                                <Box
                                  className={
                                    item?.role ? classes.grid : classes.gridBg
                                  }
                                >
                                  <Box
                                    sx={{
                                      textAlign: "right",
                                    }}
                                  >
                                    <IconButton
                                      aria-controls="header-menu"
                                      aria-haspopup="true"
                                      onClick={(e) => {
                                        handleClick(e, item);
                                      }}
                                    >
                                      <MoreVertIcon className={"svgIcon"} />
                                    </IconButton>

                                    <Menu
                                      anchorEl={anchorEl}
                                      id="account-menu"
                                      open={open}
                                      onClose={handleClose}
                                      onClick={handleClose}
                                      PaperProps={{
                                        elevation: 0,
                                        sx: {
                                          overflow: "visible",
                                          filter:
                                            "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                          mt: 1.5,
                                          "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                          },
                                          "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform:
                                              "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                          },
                                        },
                                      }}
                                      transformOrigin={{
                                        horizontal: "right",
                                        vertical: "top",
                                      }}
                                      anchorOrigin={{
                                        horizontal: "right",
                                        vertical: "bottom",
                                      }}
                                    >
                                      {tabValue === 0 && (
                                        <MenuItem
                                          onClick={(e) => {
                                            handelEdit(
                                              selectedItem?.id as number
                                            );
                                          }}
                                          disableRipple
                                        >
                                          {t("Edit")}
                                        </MenuItem>
                                      )}

                                      {tabValue === 0 && (
                                        <>
                                          {console.log(
                                            selectedItem?.role == Role.Admin
                                          )}
                                          {selectedItem &&
                                          selectedItem?.role ==
                                            (Role.Admin as number) ? (
                                            <>
                                              <MenuItem
                                                onClick={(e) => {
                                                  setConfirmDialog({
                                                    isOpen: true,
                                                    title: userMessage,
                                                    subTitle:
                                                      "You can undo this operation",
                                                    onConfirm: () => {
                                                      handleSwitch(switchUser);
                                                    },
                                                  });
                                                  handleClose();
                                                }}
                                                disableRipple
                                              >
                                                {t("SwitchToUser")}
                                              </MenuItem>
                                            </>
                                          ) : (
                                            <>
                                              <MenuItem
                                                onClick={(e) => {
                                                  setConfirmDialog({
                                                    isOpen: true,
                                                    title: adminMessage,
                                                    subTitle:
                                                      "You can undo this operation",
                                                    onConfirm: () => {
                                                      handleSwitch(switchAdmin);
                                                    },
                                                  });
                                                  handleClose();
                                                }}
                                                disableRipple
                                              >
                                                {t("SwitchToAdmin")}
                                              </MenuItem>
                                            </>
                                          )}
                                        </>
                                      )}

                                      <MenuItem
                                        onClick={(e) => {
                                          setConfirmDialog({
                                            isOpen: true,
                                            title:
                                              tabValue === 0
                                                ? "Are you sure to Inactivate this record"
                                                : "Are you sure to Activate this record",
                                            subTitle:
                                              "You can undo this operation",
                                            onConfirm: () => {
                                              handleSetStatus(
                                                tabValue === 0
                                                  ? inActiveStatus
                                                  : activeStatus
                                              );
                                            },
                                          });
                                          handleClose();
                                        }}
                                        disableRipple
                                      >
                                        {tabValue === 0
                                          ? t("Deactivate")
                                          : t("Activate")}
                                      </MenuItem>

                                      {tabValue === 1 && (
                                        <MenuItem
                                          onClick={(e) => {
                                            setConfirmDialog({
                                              isOpen: true,
                                              title:
                                                "Are you sure to delete this record?",
                                              subTitle:
                                                "You can't undo this operation",
                                              onConfirm: () => {
                                                handleSetStatus(deleteStatus);
                                              },
                                            });
                                            handleClose();
                                          }}
                                          disableRipple
                                        >
                                          {t("Delete")}
                                        </MenuItem>
                                      )}
                                    </Menu>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      padding: "5px 20px 20px 20px",
                                    }}
                                  >
                                    <Box sx={{ marginLeft: "12px" }}>
                                      <Typography
                                        sx={{ pr: 2 }}
                                        className="typoColor"
                                      >
                                        {item?.firstName + " " + item?.lastName}
                                      </Typography>
                                      <Typography
                                        sx={{ pr: 2 }}
                                        className="typoLight"
                                      >
                                        {item?.role ? "User" : "Admin"}
                                      </Typography>
                                      <Typography
                                        sx={{ pr: 2 }}
                                        className="typoColor"
                                      >
                                        {item.email}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          } else {
                            return (
                              <Grid item xs={12} lg={3} md={3} key={i}>
                                <Box
                                  className={
                                    item?.role ? classes.grid : classes.gridBg
                                  }
                                >
                                  <Box
                                    sx={{
                                      textAlign: "right",
                                    }}
                                  >
                                    <IconButton
                                      aria-controls="header-menu"
                                      aria-haspopup="true"
                                      onClick={(e) => {
                                        handleClick(e, item);
                                      }}
                                    >
                                      <MoreVertIcon className={"svgIcon"} />
                                    </IconButton>

                                    <Menu
                                      anchorEl={anchorEl}
                                      id="account-menu"
                                      open={open}
                                      onClose={handleClose}
                                      onClick={handleClose}
                                      PaperProps={{
                                        elevation: 0,
                                        sx: {
                                          overflow: "visible",
                                          filter:
                                            "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                          mt: 1.5,
                                          "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                          },
                                          "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform:
                                              "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                          },
                                        },
                                      }}
                                      transformOrigin={{
                                        horizontal: "right",
                                        vertical: "top",
                                      }}
                                      anchorOrigin={{
                                        horizontal: "right",
                                        vertical: "bottom",
                                      }}
                                    >
                                      {tabValue === 0 && (
                                        <MenuItem
                                          onClick={(e) => {
                                            handelEdit(
                                              selectedItem?.id as number
                                            );
                                          }}
                                          disableRipple
                                        >
                                          {t("Edit")}
                                        </MenuItem>
                                      )}

                                      {tabValue === 0 && (
                                        <>
                                          {console.log(
                                            selectedItem?.role == Role.Admin
                                          )}
                                          {selectedItem &&
                                          selectedItem?.role ==
                                            (Role.Admin as number) ? (
                                            <>
                                              <MenuItem
                                                onClick={(e) => {
                                                  setConfirmDialog({
                                                    isOpen: true,
                                                    title: userMessage,
                                                    subTitle:
                                                      "You can undo this operation",
                                                    onConfirm: () => {
                                                      handleSwitch(switchUser);
                                                    },
                                                  });
                                                  handleClose();
                                                }}
                                                disableRipple
                                              >
                                                {t("SwitchToUser")}
                                              </MenuItem>
                                            </>
                                          ) : (
                                            <>
                                              <MenuItem
                                                onClick={(e) => {
                                                  setConfirmDialog({
                                                    isOpen: true,
                                                    title: adminMessage,
                                                    subTitle:
                                                      "You can undo this operation",
                                                    onConfirm: () => {
                                                      handleSwitch(switchAdmin);
                                                    },
                                                  });
                                                  handleClose();
                                                }}
                                                disableRipple
                                              >
                                                {t("SwitchToAdmin")}
                                              </MenuItem>
                                            </>
                                          )}
                                        </>
                                      )}

                                      <MenuItem
                                        onClick={(e) => {
                                          setConfirmDialog({
                                            isOpen: true,
                                            title:
                                              tabValue === 0
                                                ? "Are you sure to Inactivate this record"
                                                : "Are you sure to Activate this record",
                                            subTitle:
                                              "You can undo this operation",
                                            onConfirm: () => {
                                              handleSetStatus(
                                                tabValue === 0
                                                  ? inActiveStatus
                                                  : activeStatus
                                              );
                                            },
                                          });
                                          handleClose();
                                        }}
                                        disableRipple
                                      >
                                        {tabValue === 0
                                          ? t("Deactivate")
                                          : t("Activate")}
                                      </MenuItem>

                                      {tabValue === 1 && (
                                        <MenuItem
                                          onClick={(e) => {
                                            setConfirmDialog({
                                              isOpen: true,
                                              title:
                                                "Are you sure to delete this record?",
                                              subTitle:
                                                "You can't undo this operation",
                                              onConfirm: () => {
                                                handleSetStatus(deleteStatus);
                                              },
                                            });
                                            handleClose();
                                          }}
                                          disableRipple
                                        >
                                          {t("Delete")}
                                        </MenuItem>
                                      )}
                                    </Menu>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      padding: "5px 20px 20px 20px",
                                    }}
                                  >
                                    <Box sx={{ marginLeft: "12px" }}>
                                      <Typography
                                        sx={{ pr: 2 }}
                                        className="typoColor"
                                      >
                                        {item?.firstName + " " + item?.lastName}
                                      </Typography>
                                      <Typography
                                        sx={{ pr: 2 }}
                                        className="typoLight"
                                      >
                                        {item?.role ? "User" : "Admin"}
                                      </Typography>
                                      <Typography
                                        sx={{ pr: 2 }}
                                        className="typoColor"
                                      >
                                        {item.email}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          }
                        })}
                    </Grid>
                  </>
                ) : (
                  <NoData dataType="users" />
                )}
                {isLoading && (
                  <Grid container spacing={6} sx={{ pt: 2, pb: 2 }}>
                    {[1, 2, 3, 4].map((item, index) => (
                      <Grid item xs={12} lg={3} md={3} key={index}>
                        <Box
                          sx={{
                            color: "#333",
                            background: "#FFFFFF",
                            border: "0.5px solid #CCCCCC",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                            height: 140,
                          }}
                        >
                          <Box
                            sx={{
                              padding: "50px 20px 20px 20px",
                            }}
                          >
                            <Box>
                              <Skeleton
                                animation="wave"
                                height={20}
                                width="60%"
                                style={{ marginBottom: 8 }}
                              />
                              <Skeleton
                                animation="wave"
                                height={15}
                                width="50%"
                                style={{ marginBottom: 8 }}
                              />
                              <Skeleton
                                animation="wave"
                                height={12}
                                width="80%"
                                style={{ marginBottom: 6 }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <DevConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default UserList;
