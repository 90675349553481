import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const XlsxIcon: FC<Props> = ({ fill, width, height }) => {
  return (


<svg  width={width}
      height={height} viewBox="0 0 380 500" fill={fill} xmlns="http://www.w3.org/2000/svg">
  <path d="M100.537 437.213L88.8495 461.109H70.7461L91.5392 421.696L71.9845 384.146H90.088L100.536 405.767L110.985 384.146H129.088L109.537 421.696L130.33 461.109H112.227L100.537 437.213Z" fill={fill} />
  <path d="M136.213 384.149H152.677V447.08H185.159V461.15L136.213 461.146V384.149Z" fill={fill} />
  <path d="M218.262 462.149C212.509 462.115 206.867 460.579 201.883 457.7C199.1 456.215 196.61 454.237 194.54 451.858C192.25 449.107 190.403 446.02 189.063 442.703L202.857 436.668C203.634 438.369 204.535 440.009 205.547 441.583C206.463 442.995 207.579 444.269 208.868 445.358C210.15 446.416 211.608 447.24 213.178 447.786C214.956 448.395 216.827 448.687 218.705 448.649C220.117 448.664 221.53 448.541 222.919 448.279C224.216 448.037 225.459 447.563 226.59 446.882C227.676 446.232 228.569 445.304 229.177 444.192C229.842 442.856 230.162 441.375 230.108 439.882C230.027 437.195 228.53 434.755 226.167 433.47C222.9 431.349 219.398 429.614 215.73 428.297C209.307 426.115 203.265 422.937 197.822 418.884C193.624 415.232 191.334 409.855 191.615 404.298C191.515 398.371 194.197 392.741 198.865 389.088C203.984 384.97 210.407 382.83 216.969 383.053C222.741 382.965 228.418 384.504 233.348 387.502C235.792 388.98 237.959 390.87 239.756 393.09C241.838 395.738 243.547 398.663 244.832 401.777L229.615 406.433C228.557 403.743 226.956 401.296 224.92 399.244C222.684 397.305 219.767 396.339 216.815 396.554C215.684 396.558 214.56 396.677 213.451 396.916C212.366 397.139 211.323 397.52 210.349 398.055C208.329 399.148 207.109 401.295 207.194 403.589C207.209 404.667 207.552 405.713 208.179 406.587C209.076 407.761 210.226 408.715 211.546 409.381C215.129 411.363 218.882 413.026 222.753 414.346C228.426 416.243 233.741 419.076 238.478 422.728C243.431 426.48 246.187 432.453 245.825 438.657C246.199 445.284 243.535 451.723 238.582 456.141C232.729 460.474 225.532 462.602 218.262 462.148L218.262 462.149Z" fill={fill} />
  <path d="M278.783 437.213L267.096 461.109H248.992L269.785 421.696L250.235 384.146H268.338L278.786 405.767L289.235 384.146H307.338L287.787 421.696L308.58 461.109H290.477L278.783 437.213Z" fill={fill} />
  <path d="M74.6758 74.5237H186.774V109.022H74.6758V74.5237Z" fill={fill} />
  <path d="M204.005 229.755H304.655V264.254H204.005V229.755Z" fill={fill} />
  <path d="M204.005 178.021H304.655V212.52H204.005V178.021Z" fill={fill} />
  <path d="M74.6758 126.267H186.774V160.766H74.6758V126.267Z" fill={fill} />
  <path d="M377.937 126.987C377.763 126.814 377.59 126.556 377.421 126.383H377.417C377.244 126.132 377.04 125.901 376.813 125.694L253.621 2.5015C253.447 2.32832 253.19 2.15513 253.016 1.98579C252.843 1.81261 252.501 1.55476 252.328 1.38158L251.034 0H34.4828C25.3387 0 16.5675 3.63301 10.0985 10.0985C3.633 16.5679 0 25.3389 0 34.4828V465.517C0 474.661 3.633 483.433 10.0985 489.901C16.5679 496.367 25.3389 500 34.4828 500H344.828C353.972 500 362.743 496.367 369.212 489.901C375.677 483.432 379.31 474.661 379.31 465.517V128.276L377.937 126.987ZM256.124 29.3995L349.92 123.196H273.365C268.797 123.18 264.421 121.36 261.189 118.131C257.96 114.898 256.139 110.522 256.124 105.955L256.124 29.3995ZM57.4243 169.4V65.9513C57.4243 61.1907 61.2844 57.3306 66.045 57.3306H195.355C197.641 57.3306 199.831 58.2388 201.451 59.8552C203.068 61.4715 203.976 63.6652 203.976 65.9513V160.779H313.267C315.553 160.779 317.746 161.687 319.363 163.303C320.979 164.92 321.887 167.114 321.887 169.4V272.887C321.887 275.173 320.979 277.367 319.363 278.983C317.746 280.6 315.553 281.508 313.267 281.508H66.045C61.2844 281.508 57.4243 277.648 57.4243 272.887V169.4ZM362.065 465.518C362.049 470.086 360.229 474.466 357 477.694C353.771 480.923 349.391 482.743 344.824 482.759H34.4788C29.9107 482.743 25.5311 480.923 22.3025 477.694C19.0739 474.465 17.2532 470.086 17.2379 465.518V331.547H135.602L158.878 358.187C160.498 360.065 162.865 361.135 165.344 361.119H362.073L362.065 465.518Z" fill={fill} />
  <path d="M74.6758 178.021H186.774V212.52H74.6758V178.021Z" fill={fill} />
  <path d="M74.6758 229.755H186.774V264.254H74.6758V229.755Z" fill={fill} />
</svg>



  );
};

export default XlsxIcon;
