import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const ZipIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 469"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M266.109 0H30.1255C13.3891 0 0 15.0628 0 31.7992V436.82C0 453.557 13.3891 468.619 30.1255 468.619H369.875C386.611 468.619 400 453.557 400 436.82V132.218L266.109 0ZM200.837 334.728C200.837 363.18 179.08 384.937 150.628 384.937C122.176 384.937 100.418 363.18 100.418 334.728V267.782H200.837V334.728ZM187.448 133.891C197.49 133.891 204.184 140.586 204.184 150.628C204.184 160.669 197.49 167.364 187.448 167.364H150.628V200.837H187.448C197.49 200.837 204.184 207.531 204.184 217.573C204.184 227.615 197.49 234.31 187.448 234.31H150.628V200.837H113.808C103.766 200.837 97.0711 194.142 97.0711 184.1C97.0711 174.059 103.766 167.364 113.808 167.364H150.628V133.891H113.808C103.766 133.891 97.0711 127.197 97.0711 117.155C97.0711 107.113 103.766 100.418 113.808 100.418H150.628V66.9456H187.448C197.49 66.9456 204.184 73.6402 204.184 83.682C204.184 93.7239 197.49 100.418 187.448 100.418H150.628V133.891H187.448ZM266.109 133.891V41.841L361.506 133.891H266.109Z"
        fill={fill}
      />
    </svg>
  );
};

export default ZipIcon;
