import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { FilePaginationItem } from "../api/fetch-client";
import { theme } from "../theme";
import { NoData } from "./icons/NoData";
import FolderIcon from "./icons/FolderIcon";
import { getUserDetails } from "../helpers/util";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Skeleton from "@mui/material/Skeleton";

interface FolderProps {
  isLoading?: boolean;
  folders?: FilePaginationItem[] | [];
  handleClick?: any;
  handleDoubleClick?: any;
  selectedItem?: FilePaginationItem | undefined;
  lastElementRef?: any;
}

function AllFolder(props: FolderProps) {
  const user = getUserDetails();
  const {
    isLoading,
    folders,
    handleClick,
    handleDoubleClick,
    selectedItem,
    lastElementRef,
  } = props;
  const matchesTab = useMediaQuery(
    (_theme: any) => _theme?.breakpoints?.down("md") ?? "768px"
  );
  return (
    <>
      <Grid container spacing={8} sx={{ mt: 1 }}>
        <Grid item xs={12} sx={{ paddingTop: "30px !important" }}>
          <Typography variant="h6">
            <b>Folders</b>
          </Typography>
        </Grid>
        {!isLoading ? (
          <>
            {folders && folders.length > 0 ? (
              <>
                {folders &&
                  folders.map((item: any, i: any) => {
                    if (folders.length === i + 1) {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={2.3}
                          md={3}
                          key={i}
                          id={`${item?.scroll}`}
                          ref={lastElementRef}
                          sx={{ paddingTop: "30px !important" }}
                        >
                          <Box
                            sx={{
                              borderRadius: "04px",
                              cursor: "pointer",
                              backgroundColor:
                                item.id === selectedItem?.id
                                  ? "#e8f0fe"
                                  : "#EFF2F5",
                              background: item?.scroll ? "#e8f0fe" : "",
                            }}
                            aria-controls="account-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleDoubleClick(e, item);
                            }}
                          >
                            <Box
                              sx={{
                                padding: "15px 15px 10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Typography
                                  sx={{
                                    marginRight: "5px",
                                    display: "inline",
                                  }}
                                >
                                  <FolderIcon
                                    width={28}
                                    fill={theme.palette.primary.main}
                                    height={24}
                                  />
                                </Typography>
                                <Box
                                  sx={{
                                    display: "inline",
                                    width: matchesTab ? "100%" : "70%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Box>
                                {user.role !== "1" && (
                                  <Typography
                                    sx={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      handleClick(e, item);
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          id={`${item?.scroll}`}
                          item
                          xs={12}
                          sm={6}
                          lg={2.3}
                          md={3}
                          key={i}
                          sx={{ paddingTop: "30px !important" }}
                        >
                          <Box
                            sx={{
                              borderRadius: "04px",
                              cursor: "pointer",
                              backgroundColor:
                                item.id === selectedItem?.id
                                  ? "#e8f0fe"
                                  : "#EFF2F5",
                              background: item?.scroll ? "#e8f0fe" : "",
                            }}
                            aria-controls="account-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleDoubleClick(e, item);
                            }}
                          >
                            <Box
                              sx={{
                                padding: "15px 15px 10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Typography
                                  sx={{
                                    marginRight: "5px",
                                    display: "inline",
                                  }}
                                >
                                  <FolderIcon
                                    width={28}
                                    fill={theme.palette.primary.main}
                                    height={24}
                                  />
                                </Typography>
                                <Box
                                  sx={{
                                    display: "inline",
                                    width: matchesTab ? "100%" : "70%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Box>
                                {user.role !== "1" && (
                                  <Typography
                                    sx={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      handleClick(e, item);
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }
                  })}
              </>
            ) : (
              <NoData dataType="folders" />
            )}
          </>
        ) : (
          <>
            {[1, 2, 3, 4, 5].map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={2.3}
                md={2.3}
                key={index}
                sx={{ paddingTop: "30px !important" }}
              >
                <Box
                  sx={{
                    borderRadius: "04px",
                    backgroundColor: "#EFF2F5",
                  }}
                  aria-controls="account-menu"
                  aria-haspopup="true"
                >
                  <Box
                    sx={{
                      padding: "20px 15px 15px",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="15%"
                      sx={{
                        display: "inline-block",
                        marginRight: 2,
                      }}
                    />

                    <Skeleton
                      animation="wave"
                      height={20}
                      width="75%"
                      sx={{ display: "inline-block" }}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
}

export default AllFolder;
