import React, { FC } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'
const DownloadIcon: FC = () => {
  const theme = useTheme<any>()

  return (
    <Box sx={{ mr: '12px' }}>
      <svg
        width={13}
        height={16}
        viewBox='0 0 13 16'
        fill={theme.palette.primary.main}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.5009 14.999C11.6259 14.9988 11.7464 15.0453 11.8387 15.1295C11.931 15.2137 11.9885 15.3294 11.9998 15.4538C12.0111 15.5783 11.9754 15.7024 11.8997 15.8018C11.824 15.9013 11.7139 15.9687 11.5909 15.991L11.5009 15.999H0.500921C0.375975 15.9992 0.255471 15.9527 0.163138 15.8685C0.0708051 15.7843 0.0133357 15.6686 0.00204624 15.5442C-0.00924323 15.4197 0.0264655 15.2956 0.102141 15.1962C0.177817 15.0967 0.287974 15.0293 0.410921 15.007L0.500921 14.999H11.5009ZM6.00092 2.78103e-08C6.11795 -3.89861e-05 6.23128 0.0409714 6.32118 0.115889C6.41108 0.190807 6.47186 0.294886 6.49292 0.41L6.50092 0.5L6.49692 12.294L10.1419 8.646C10.2245 8.56302 10.3336 8.51166 10.4502 8.50088C10.5668 8.4901 10.6835 8.52058 10.7799 8.587L10.8499 8.645C10.9327 8.72773 10.9839 8.8369 10.9945 8.95349C11.0051 9.07008 10.9745 9.18669 10.9079 9.283L10.8499 9.353L6.35792 13.853C6.28827 13.9226 6.19952 13.9699 6.10292 13.989L5.99692 13.999C5.92065 13.9989 5.8454 13.9814 5.77693 13.9478C5.70847 13.9142 5.6486 13.8653 5.60192 13.805L1.14492 9.353C1.05599 9.26498 1.0033 9.14681 0.997245 9.02183C0.991191 8.89686 1.03222 8.77415 1.11222 8.67794C1.19222 8.58174 1.30539 8.51902 1.42938 8.50219C1.55337 8.48535 1.67916 8.51561 1.78192 8.587L1.85192 8.645L5.49692 12.286L5.50092 0.501C5.50079 0.435255 5.51363 0.370129 5.53869 0.309351C5.56376 0.248573 5.60057 0.193335 5.64701 0.1468C5.69346 0.100265 5.74862 0.0633457 5.80935 0.0381558C5.87008 0.012966 5.93518 -1.0368e-07 6.00092 2.78103e-08Z'
          fill='black'
        />
      </svg>
    </Box>
  )
}

export default DownloadIcon
