import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const XlsIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 500"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 50C0 36.7392 4.86262 24.0215 13.5181 14.6447C22.1737 5.26784 33.9131 0 46.1538 0L298.677 0L400 109.767V450C400 463.261 395.137 475.979 386.482 485.355C377.826 494.732 366.087 500 353.846 500H46.1538C33.9131 500 22.1737 494.732 13.5181 485.355C4.86262 475.979 0 463.261 0 450V50ZM61.5385 243.1V200H30.7692V256.9L55.1692 283.333L30.7692 309.767V366.667H61.5385V323.567L76.9231 306.9L92.3077 323.567V366.667H123.077V309.767L98.6769 283.333L123.077 256.9V200H92.3077V243.1L76.9231 259.767L61.5385 243.1ZM153.846 200H184.615V333.333H246.154V366.667H153.846V200ZM369.231 200H276.923V300H338.462V333.333H276.923V366.667H369.231V266.667H307.692V233.333H369.231V200Z"
        fill={fill}
      />
    </svg>
  );
};

export default XlsIcon;
