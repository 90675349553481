import React, { FC } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/styles'
const UploadIcon: FC = () => {
  const theme = useTheme<any>()

  return (
    <Box sx={{ mr: '12px' }}>
      <svg
        width={42}
        height={33}
        viewBox='0 0 42 33'
        fill={theme.palette.primary.main}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.8686 8.96848H33.0186C34.1985 8.96867 35.3668 9.20167 36.4565 9.65413C37.5462 10.1066 38.5359 10.7696 39.3689 11.6052C41.0538 13.2939 42 15.582 42 17.9675C42 20.353 41.0538 22.6411 39.3689 24.3299C38.5359 25.1655 37.5462 25.8285 36.4565 26.2809C35.3668 26.7334 34.1985 26.9664 33.0186 26.9666H27.0342V23.9669H33.0186C34.5633 23.8978 36.0218 23.2355 37.0905 22.118C38.1592 21.0005 38.7556 19.5138 38.7556 17.9675C38.7556 16.4213 38.1592 14.9346 37.0905 13.8171C36.0218 12.6995 34.5633 12.0373 33.0186 11.9682H30.2769L29.9079 9.39743C29.6828 7.78683 28.9382 6.2937 27.7872 5.14484C26.6361 3.99598 25.1416 3.25418 23.5306 3.0321C21.9186 2.8119 20.2789 3.12665 18.863 3.92805C17.4472 4.72946 16.3333 5.97326 15.6924 7.46864L14.6935 9.7544L12.2668 9.18746C11.712 9.04986 11.1434 8.97639 10.5719 8.96848C8.58613 8.96848 6.68134 9.7574 5.28048 11.1642C4.2365 12.214 3.52615 13.5489 3.23876 15.0012C2.95136 16.4536 3.09974 17.9584 3.66524 19.3267C4.23074 20.6949 5.18812 21.8654 6.41701 22.691C7.6459 23.5167 9.09146 23.9606 10.5719 23.9669H18.0531V26.9666H10.5719C9.08614 26.98 7.61457 26.6765 6.25526 26.0765C4.89595 25.4765 3.68011 24.5937 2.68875 23.487C1.18983 21.8177 0.264684 19.7124 0.0487725 17.4794C-0.167139 15.2463 0.337528 13.0028 1.48888 11.0773C2.25145 9.80156 3.2764 8.70247 4.49582 7.85281C5.71523 7.00315 7.10129 6.42231 8.56214 6.14877C10.02 5.8788 11.5228 5.9178 12.9657 6.27176C13.8748 4.19002 15.4372 2.46096 17.4164 1.34607C19.3956 0.231176 21.684 -0.208897 23.9355 0.0924056C26.188 0.397207 28.2794 1.42908 29.8918 3.03118C31.5041 4.63328 32.5494 6.718 32.8686 8.96848ZM27.8801 20.5263L23.9385 16.5877V32.894H20.9568V16.6957L17.1232 20.5293L15.0025 18.4055L21.4398 11.9682H23.5636L30.0009 18.4055L27.8801 20.5263Z'
          fill='#CCCCCC'
        />
      </svg>
    </Box>
  )
}

export default UploadIcon
