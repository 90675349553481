import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import {
  ApiException,
  FileFolderMoveRequest,
  FilePaginationItem,
  FilePaginationItemIEnumerableResponse,
  IFileFolderMoveRequest,
  Query,
} from "../api/fetch-client";
import { theme } from "../theme";
import { NoData } from "./icons/NoData";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "./icons/FolderIcon";
import { PageLoading } from "./icons/PageLoading";
import { SnackbarContext } from "../context/SnackbarContext";

interface FolderMoveProps {
  currentFolder?: FilePaginationItem; // Current Folder on which data was loaded
  handelclose?: any;
  selectedItem?: FilePaginationItem; //Inital Folder on which Move Inicated
}

function FolderMove(props: FolderMoveProps) {
  const { setSnack } = useContext<any>(SnackbarContext);

  const [foldersFiles, setFoldersFiles] = useState<FilePaginationItem[] | []>(
    []
  );
  const [currentFolder, setcurrentFolder] = useState<
    FilePaginationItem | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedMenuId, setSelectedMenuId] = React.useState<number>();

  const handleMoveFolderBack = (item: any) => {
    loadData(item?.parentId);
  };
  const handleMoveFolderClose = () => {
    props.handelclose();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    id: number
  ) => {
    event.preventDefault();
    switch (event.detail) {
      case 1:
        setSelectedMenuId(id);
        break;
      case 2:
        loadData(id);
        break;
    }
  };
  const handleDoubleClick = (
    event: React.MouseEvent<HTMLElement>,
    item: any
  ) => {
    switch (event.detail) {
      case 2:
        loadData(item?.id);
        setcurrentFolder(item);
        break;
    }
  };
  const moveFolder = async () => {
    //TODO API CALL & call handelclose
    let data: IFileFolderMoveRequest = {
      destinationParentId: selectedMenuId ? selectedMenuId : currentFolder?.id,
      id: props.selectedItem?.id,
      parentId: props.selectedItem?.parentId,
    };
    Query.Client.moveFolder(data as FileFolderMoveRequest)
      .then((response: any) => {
        if (response.success) {
          setSnack({
            message: "Folder move successfully",
            open: true,
            color: "success",
          });
          props.handelclose();
        } else {
          setSnack({ message: response.message, open: true, color: "error" });
        }
      })
      .catch((error: ApiException) => {
        setSnack({ message: error.message, open: true, color: "error" });
        setIsLoading(false);
      });
  };
  const loadData = async (id: number | undefined) => {
    setIsLoading(true);
    if (id == 0) {
      id = undefined;
    }
    Query.Client.getFileFolderDetails(id)
      .then((response: FilePaginationItemIEnumerableResponse) => {
        if (response.success) {
          setFoldersFiles(response?.data ? response?.data : []);
        }

        setIsLoading(false);
      })
      .catch((error: ApiException) => {
        setSnack({ message: error.message, open: true, color: "error" });
        setIsLoading(false);
      });

    setIsLoading(false);
  };
  useEffect(() => {
    loadData(props.selectedItem?.parentId);
  }, []);
  return (
    <>
      <Box sx={{ backgroundColor: "#f1f1f1" }}>
        <Grid container spacing={2}>
          <Grid item xs={1} sx={{ paddingTop: "5px !important" }}>
            <IconButton onClick={() => handleMoveFolderBack(currentFolder)}>
              <ArrowBackOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: "5px !important" }}>
            <Typography
              sx={{
                padding: "8px",
                display: "inline-block",
              }}
            >
              Folders
            </Typography>
          </Grid>

          <Grid item xs={5} sx={{ paddingTop: "5px !important" }}>
            <Typography
              style={{ float: "right" }}
              onClick={handleMoveFolderClose}
            >
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          height: "198px",
          overflowX: "hidden",
        }}
      >
        {!isLoading ? (
          foldersFiles.length > 0 ? (
            <>
              {foldersFiles &&
                foldersFiles.map((item: FilePaginationItem, index: number) => {
                  if (item.type === 1)
                    return (
                      <MenuItem
                        disableRipple
                        disabled={
                          item.id === props.selectedItem?.id ? true : false
                        }
                        key={index}
                        selected={item.id === selectedMenuId}
                        onClick={(event) => {
                          handleMenuItemClick(event, item?.id as number);
                          handleDoubleClick(event, item);
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Typography sx={{ marginRight: "5px" }}>
                            <FolderIcon
                              width={18}
                              fill={
                                item.id === selectedMenuId ? "#FFF" : "#333"
                              }
                              height={18}
                            />
                          </Typography>
                          <Typography
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    );
                })}
            </>
          ) : (
            <NoData  dataType="folders"/>
          )
        ) : (
          <PageLoading />
        )}
      </Box>
      <Box sx={{ backgroundColor: "#f1f1f1" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ padding: "5px !important" }}>
            <Button
              disabled={selectedMenuId ? false : true}
              sx={{
                bgcolor: "primary.main",
                textTransform: "capitalize",
                color: "#fff",
                float: "right",
                ":hover": {
                  bgcolor: "primary.dark",
                  color: "white",
                },
              }}
              type="submit"
              onClick={() => moveFolder()}
            >
              Move
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default FolderMove;
