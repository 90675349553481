import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const FlaIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 390 500"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M243.469 167.372L167.061 123.304C164.439 121.79 161.208 121.79 158.586 123.304C155.964 124.817 154.349 127.617 154.349 130.643V218.829C154.349 221.859 155.964 224.655 158.586 226.168C161.208 227.682 164.439 227.682 167.061 226.168L243.469 182.051C246.091 180.538 247.706 177.738 247.706 174.711C247.706 171.685 246.091 168.885 243.469 167.372Z"
        fill={fill}
      />
      <path
        d="M244.506 435.897H258.735L251.83 409.046L244.506 435.897Z"
        fill={fill}
      />
      <path
        d="M381.359 348.893V126.064L255.295 0H42.3732C33.3841 0 24.7614 3.57146 18.4021 9.92743C12.0461 16.2872 8.47464 24.9096 8.47464 33.8985V319.847C5.9058 321.323 3.76816 323.445 2.28137 326.01C0.794484 328.572 0.00755453 331.477 0 334.44V457.627C0.0151333 468.86 4.48323 479.631 12.4243 487.576C20.3692 495.517 31.1402 499.984 42.3732 500H347.46C358.693 499.985 369.464 495.517 377.409 487.576C385.35 479.631 389.818 468.86 389.833 457.627V363.497C389.826 360.535 389.042 357.626 387.552 355.061C386.065 352.499 383.927 350.373 381.359 348.898V348.893ZM277.203 138.045H285.155C296.937 166.851 294.307 199.55 278.069 226.105C261.835 252.66 233.933 269.909 202.917 272.553C171.905 275.198 141.483 262.928 120.985 239.506C100.484 216.087 92.3537 184.307 99.0879 153.916C105.818 123.528 126.608 98.1576 155.081 85.5812C183.553 73.0097 216.31 74.7347 243.304 90.2309V104.146C243.304 113.135 246.875 121.758 253.235 128.113C259.591 134.473 268.214 138.044 277.202 138.044L277.203 138.045ZM163.139 403.761H130.292V419.219H154.808L154.902 433.051L130.326 433.153V465.591H114.129V389.929H163.139L163.139 403.761ZM218.766 465.626H170.672V389.929H186.835V451.794H218.767L218.766 465.626ZM266.367 465.626L262.292 449.762L240.844 449.758L236.444 465.592H220.172L242.043 389.929H261.152L282.714 465.592L266.367 465.626ZM364.411 346.545H178.696L158.357 323.274C155.137 319.596 150.487 317.485 145.599 317.485H25.4239V33.8902C25.4239 29.3957 27.2097 25.0826 30.3876 21.9047C33.5656 18.7267 37.8786 16.9409 42.3732 16.9409H243.304V71.1786C211.123 56.087 173.763 56.764 142.151 73.0135C110.537 89.2586 88.2389 119.241 81.7768 154.196C75.3148 189.15 85.4202 225.121 109.134 251.601C132.847 278.076 167.496 292.067 202.949 289.479C238.399 286.891 270.647 268.016 290.267 238.377C309.884 208.738 314.659 171.678 303.188 138.028H364.409L364.411 346.545Z"
        fill={fill}
      />
    </svg>
  );
};

export default FlaIcon;
