import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import MuiTextField from "../../../components/MuiTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ApiException,
  FileFolderItemResultResponse,
  FileFolderItemResult,
  DevResponse,
  FilePaginationItem,
  Query,
  SaveFolderModel,
} from "../../../api/fetch-client";
import { SnackbarContext } from "../../../context/SnackbarContext";
interface Props {
  handleClose?: any;
  selectedFolderFile?: FilePaginationItem;
  currentFolderId?: number;
  setFoldersFiles?: any;
  foldersFiles?: any;
  scroll?:any
}
interface FormFields {
  name?: string;
  type?: number;
  id?: number | undefined;
  parentId?: number | undefined;
}
type ValuesSchema = {
  name?: any;
};
const AddFolder: FC<Props> = ({
  handleClose,
  selectedFolderFile,
  currentFolderId,
  setFoldersFiles,
  foldersFiles,
  scroll
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setSnack } = useContext<any>(SnackbarContext);

  const [extension, setExtension] = React.useState<string>("");
  useEffect(() => {
    let type =
      selectedFolderFile && selectedFolderFile.type === 0
        ? selectedFolderFile?.name?.substring(
            0,
            selectedFolderFile?.name?.indexOf(".")
          )
        : selectedFolderFile?.name;

    if (selectedFolderFile) {
      let extension: any =
        selectedFolderFile?.type === 0 &&
        selectedFolderFile?.name?.substring(
          selectedFolderFile?.name?.lastIndexOf(".") + 1
        );
      setExtension(extension);
      setFieldValue("id", selectedFolderFile?.id ? selectedFolderFile?.id : 0);
      setFieldValue("name", type ? type : "");
      setFieldValue(
        "parentId",
        selectedFolderFile?.parentId ? selectedFolderFile?.parentId : undefined
      );
      setFieldValue(
        "type",
        selectedFolderFile?.type ? selectedFolderFile?.type : 1
      );
    }
  }, [selectedFolderFile]);
  const validationSchema = yup.object<ValuesSchema>({
    name: yup
      .string()
      .min(3, " Name must be at least 3 characters long")
      .required("Name is required"),
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    values,
    errors,
  } = useFormik<FormFields>({
    initialValues: {
      parentId: currentFolderId ? currentFolderId : undefined,
      id: selectedFolderFile?.id ? selectedFolderFile?.id : undefined,
      name: "",
      type: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (selectedFolderFile && (selectedFolderFile?.id as number) > 0) {
        update(values as SaveFolderModel);
      } else {
        add(values as SaveFolderModel);
      }
    },
  });

  const add = async (data: SaveFolderModel) => {
    await Query.Client.saveFolder(data)
      .then((response: FileFolderItemResultResponse) => {
        if (response.success) {
          let folder:FileFolderItemResult | undefined = response.data
          setSnack({ message: "Folder created", open: true, color: "success" });
          let updateFolder = {
            id:folder?.id,
            name: data?.name,
            parentId: data?.parentId,
            status: 0,
            type: 1,
            scroll:'true'
          };
          const _updateFolder = [...(foldersFiles ?? []), updateFolder];
          setFoldersFiles(_updateFolder);
          scroll(_updateFolder)
          handleClose();
        } else {
        }
      })
      .catch((error: ApiException) => {
        setSnack({ message: error.message, open: true, color: "error" });
        setIsLoading(false);
      });
  };
  const update = async (data: SaveFolderModel) => {
    let updateData = data;
    updateData.name =
      selectedFolderFile && selectedFolderFile.type === 0
        ? data.name + "." + extension
        : data.name;
    //File

    if (selectedFolderFile && selectedFolderFile.type === 0) {
      await Query.Client.renameFile(data)
        .then((response: DevResponse) => {
          if (response.success) {
            setSnack({
              message: "File name update successfully",
              open: true,
              color: "success",
            });
            stateRecordUpdate(data)
            handleClose();
          } else {
            setSnack({ message: response.message, open: true, color: "erroe" });
          }
        })
        .catch((error: ApiException) => {
          setSnack({
            message: "File name already exist",
            open: true,
            color: "error",
          });
          setIsLoading(false);
        });
    }
    //Folder
    if (selectedFolderFile && selectedFolderFile.type === 1) {
      await Query.Client.renameFolder(data)
        .then((response: any) => {
          if (response.success) {
            setSnack({
              message: "Folder name update successfully",
              open: true,
              color: "success",
            });
            stateRecordUpdate(data)
            handleClose();
          } else {
            setSnack({
              message: "Folder name already exist",
              open: true,
              color: "error",
            });
          }
        })
        .catch((error: ApiException) => {
          setSnack({ message: error.message, open: true, color: "error" });
          setIsLoading(false);
        });
    }
  };
  const stateRecordUpdate = (data: SaveFolderModel) => {
    //File
    if (selectedFolderFile && selectedFolderFile.type === 0) {
            let updatedFoldersFiles = foldersFiles.map((item:any) => {
              if (data.id === item.id) {
                return {
                  ...item,
                  downloadUrl: selectedFolderFile?.downloadUrl ? selectedFolderFile?.downloadUrl : undefined,
                  id:data.id,
                  name: data.name,
                  parentId: data.parentId,
                  status: 0,
                  type: 0,
                };
              } else {
                return { ...item };
              }
            });
            const _updateFile = [...(updatedFoldersFiles ?? [])];
            setFoldersFiles(_updateFile);
    }

    //Folder
    if (selectedFolderFile && selectedFolderFile.type === 1) {
    let updatedFoldersFiles = foldersFiles.map((item:any) => {
      if (data.id === item.id) {
        return {
          ...item,
          id:data.id,
          name: data.name,
          parentId: data.parentId,
          status: 0,
          type: 1,
        };
      } else {
        return { ...item };
      }
    });
    const _updateFolder = [...(updatedFoldersFiles ?? [])];
    setFoldersFiles(_updateFolder);
  }
  };
  return (
    <>
      <Box sx={{ m: 2 }}>
        <form onSubmit={handleSubmit} id="UserAddEditForm">
          <Grid container spacing={2}>
            <Grid item xs={12} className="fullWidth" sx={{ pb: 3 }}>
              <Typography> Name*</Typography>
              <MuiTextField
                name="name"
                id="name"
                value={values.name}
                extension={extension ? "." + extension : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
          </Grid>

          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Button
                sx={{
                  bgcolor: "secondary.main",
                  textTransform: "capitalize",
                  color: "#fff",
                  minWidth: "100%",
                  ":hover": {
                    bgcolor: "primary.dark",
                    color: "white",
                  },
                }}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default AddFolder;
