import React from 'react'
import { paths } from './paths'
import UserList from '../pages/admin/user/UserList'
import Login from '../pages/shared/auth/Login'
import Recover from '../pages/shared/auth/Recover'
import SetPassword from '../pages/shared/auth/SetPassword'
import Profile from '../pages/shared/profile/Profile'
import Files from '../pages/shared/files/Files'

type TypeRoutes = {
  DEFAULT: {
    component: React.FC
    route: string
  }
  FILES: {
    component: React.FC
    routeAdmin: string
    routeUser: string
  }
  PROFILE: {
    component: React.FC
    routeAdmin: string
    routeUser: string
  }
  SIGNIN: {
    component: React.FC
    routeAdmin: string
    routeUser: string
  }
  RECOVER: {
    component: React.FC
    routeAdmin: string
    routeUser: string
  }
  SET_PASSWORD: {
    component: React.FC
    routeAdmin: string
    routeUser: string
  }

  ADMIN_USERLIST: {
    component: React.FC
    route: string
  }
}

export const routes: TypeRoutes = {
  DEFAULT: {
    component: Login,
    route: paths.DEFAULT
  },
  FILES: {
    component: Files,
    routeAdmin: paths.ADMIN_FILES,
    routeUser: paths.USER_FILES
  },
  PROFILE: {
    component: Profile,
    routeAdmin: paths.ADMIN_PROFILE,
    routeUser: paths.USER_PROFILE
  },
  SIGNIN: {
    component: Login,
    routeAdmin: paths.ADMIN_SIGNIN,
    routeUser: paths.USER_SIGNIN
  },
  RECOVER: {
    component: Recover,
    routeAdmin: paths.ADMIN_RECOVER,
    routeUser: paths.USER_RECOVER
  },
  SET_PASSWORD: {
    component: SetPassword,
    routeAdmin: paths.ADMIN_SET_PASSWORD,
    routeUser: paths.USER_SET_PASSWORD
  },
  ADMIN_USERLIST: {
    component: UserList,
    route: paths.ADMIN_USERLIST
  }
}
