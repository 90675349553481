import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Box,
  IconButton
} from '@mui/material'
import { AuthService } from '../../../services/AuthServices'
import { SnackbarContext } from '../../../context/SnackbarContext'
import React, { FC, useContext } from 'react'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import MuiTextField from '../../../components/MuiTextField'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { makeStyles } from '@mui/styles'
import { Loading } from '../../../components/icons/Loading'
import { ChangePasswordModel, Query } from '../../../api/fetch-client'
const useStyles = makeStyles<any>(theme => ({
  section: {
    '& .fullWidth': {
      maxWidth: '100% !important',
      minWidth: '100%',
      width: '100% !important'
    }
  },
  checkIcon: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    '& .MuiSvgIcon-root': {
      fontSize: '4rem',
      color: '#fff'
    }
  },
  closeIcon: {
    display: 'block',
    float: 'right',
    '& .MuiSvgIcon-root': {
      fontSize: '2.4rem'
    }
  }
}))

interface Props {
  handleClose?: any
}
interface ITheme {
  history: string
  _theme?: {
    breakpoints: any
  }
  breakpoints?: any
}
interface FormFields {
  currentPassword?: string
  newPassword?: string
  confirmNewPassword?: string
}
type ValuesSchema = {
  currentPassword?: any
  newPassword?: any
  confirmNewPassword?: any
}
const ChangePassword: FC<Props> = ({ handleClose }) => {
  const { setSnack } = useContext<any>(SnackbarContext)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [changePassword, setChangePassword] = React.useState<boolean>(true)

  const classes = useStyles()
  const matches = useMediaQuery(
    (_theme: ITheme) => _theme?.breakpoints?.down('sm') ?? '600'
  )

  const validationSchema = yup.object<ValuesSchema>({
    currentPassword: yup
      .string()
      .required('Current Password is required')
      .min(6, 'Password must be at least of 6 characters'),
    newPassword: yup
      .string()
      .required('New Password  is required')
      .min(6, 'New Password  must be at least 6 characters'),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Password must match')
      .required('Confirm New Password   is required')
  })

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors
  } = useFormik<FormFields>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log(values)
      setIsLoading(true)
      Query.Client.chagnePassword(values as ChangePasswordModel)
        .then((response: any) => {
          if (response.success) {
            setSnack({
              message: 'Password updated sucessfully',
              open: true,
              color: 'success'
            })
            setChangePassword(false)
          } else {
            // let statuscode:any = t(response.statusCode);
            setSnack({
              message: 'Current password not correct',
              open: true,
              color: 'error'
            })
          }
          setIsLoading(false)
        })
        .catch((error: any) => {
          console.error(error.message)
          setSnack({ message: error, open: true, color: 'error' })
          setIsLoading(false)
        })
    }
  })
  return (
    <>
      {changePassword ? (
        <Box sx={{ m: 5 }}>
          <form onSubmit={handleSubmit} id='UserAddEditForm'>
            <Grid
              container
              spacing={2}
              className={matches ? classes.section : ' '}
            >
              <Grid item xs={12} className='fullWidth' sx={{ pb: 2 }}>
                <Typography> Current Password*</Typography>
                <MuiTextField
                  name='currentPassword'
                  id='currentPassword'
                  type='password'
                  isProtected='true'
                  value={values.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.currentPassword && Boolean(errors.currentPassword)
                  }
                  helperText={touched.currentPassword && errors.currentPassword}
                />
              </Grid>

              <Grid item xs={12} className='fullWidth' sx={{ pb: 2 }}>
                <Typography>{'Enter New Password *'}</Typography>
                <MuiTextField
                  name='newPassword'
                  id='newPassword'
                  type='password'
                  isProtected='true'
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.newPassword && Boolean(errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                />
              </Grid>

              <Grid item xs={12} className='fullWidth' sx={{ pb: 2 }}>
                <Typography>Confirm New Password*</Typography>

                <MuiTextField
                  id='Password'
                  name='confirmNewPassword'
                  type='password'
                  isProtected='true'
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.confirmNewPassword &&
                    Boolean(errors.confirmNewPassword)
                  }
                  helperText={
                    touched.confirmNewPassword && errors.confirmNewPassword
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={8} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                {!isLoading ? (
                  <>
                    <Button
                      sx={{
                        bgcolor: 'secondary.main',
                        textTransform: 'capitalize',
                        color: '#fff',
                        minWidth: '100%',

                        ':hover': {
                          bgcolor: 'primary.dark',
                          color: 'white'
                        }
                      }}
                      type='submit'
                    >
                      Update
                    </Button>
                  </>
                ) : (
                  <Loading align={'left'} />
                )}
              </Grid>

              <Grid item xs={6}>
                <Button
                  sx={{
                    bgcolor: 'secondary.main',
                    textTransform: 'capitalize',
                    color: '#fff',
                    minWidth: '100%',

                    ':hover': {
                      bgcolor: 'primary.dark',
                      color: 'white'
                    }
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      ) : (
        <>
          <IconButton
            onClick={handleClose}
            disableRipple
            className={classes.closeIcon}
          >
            <CancelOutlinedIcon />
          </IconButton>
          <Box sx={{ margin: '70px 0px 70px 0px', textAlign: 'center' }}>
            <IconButton disableRipple className={classes.checkIcon}>
              <CheckOutlinedIcon />
            </IconButton>

            <Typography variant='h5' sx={{ mb: 3, mt: 3 }}>
              <b>Password Updated!</b>
            </Typography>
            <Typography variant='subtitle2'>
              Your password has been changed Successfully. Use your new password
              to login.
            </Typography>
          </Box>
        </>
      )}
    </>
  )
}

export default ChangePassword
