import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const AviIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 390 500"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M243.469 167.372L167.061 123.304C164.439 121.79 161.208 121.79 158.586 123.304C155.964 124.817 154.349 127.617 154.349 130.643V218.829C154.349 221.859 155.964 224.655 158.586 226.168C161.208 227.682 164.439 227.682 167.061 226.168L243.469 182.051C246.091 180.538 247.706 177.738 247.706 174.711C247.706 171.685 246.091 168.885 243.469 167.372Z"
        fill={fill}
      />
      <path
        d="M381.359 348.893V126.064L255.295 0H42.3732C33.3841 0 24.7614 3.57146 18.4021 9.92743C12.0461 16.2872 8.47464 24.9096 8.47464 33.8985V319.847C5.9058 321.323 3.76816 323.445 2.28137 326.01C0.794484 328.572 0.00755453 331.477 0 334.44V457.627C0.0151333 468.86 4.48323 479.631 12.4243 487.576C20.3692 495.517 31.1402 499.984 42.3732 500H347.46C358.693 499.985 369.464 495.517 377.409 487.576C385.35 479.631 389.818 468.86 389.833 457.627V363.497C389.826 360.535 389.042 357.626 387.552 355.061C386.065 352.499 383.927 350.373 381.359 348.898V348.893ZM277.203 138.045H285.155C296.937 166.851 294.307 199.55 278.069 226.105C261.835 252.66 233.933 269.909 202.917 272.553C171.905 275.198 141.483 262.928 120.985 239.506C100.484 216.087 92.3537 184.307 99.0879 153.916C105.818 123.528 126.608 98.1576 155.081 85.5812C183.553 73.0097 216.31 74.7347 243.304 90.2309V104.146C243.304 113.135 246.875 121.758 253.235 128.113C259.591 134.473 268.214 138.044 277.202 138.044L277.203 138.045ZM168.524 465.592L164.457 449.728L142.998 449.724L138.625 465.588H122.353L144.224 389.925H163.333L184.894 465.588L168.524 465.592ZM213.781 465.592H196.49L176.457 389.929H192.62L204.832 440.879L217.646 389.929H233.895L213.781 465.592ZM258.321 465.592H242.152V389.929H258.321V465.592ZM364.404 346.55H178.689L158.35 323.279C155.13 319.601 150.48 317.49 145.592 317.49H25.4171V33.8951C25.4171 29.4005 27.2029 25.0874 30.3808 21.9095C33.5588 18.7316 37.8718 16.9458 42.3664 16.9458H243.298V71.1835C211.116 56.0918 173.756 56.7688 142.144 73.0183C110.531 89.2635 88.2321 119.246 81.7701 154.2C75.308 189.155 85.4134 225.126 109.127 251.606C132.841 278.081 167.489 292.072 202.942 289.484C238.392 286.896 270.64 268.021 290.261 238.382C309.877 208.743 314.652 171.682 303.181 138.033H364.403L364.404 346.55Z"
        fill={fill}
      />
      <path
        d="M146.675 435.897H160.903L153.988 409.046L146.675 435.897Z"
        fill={fill}
      />
    </svg>
  );
};

export default AviIcon;
