import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const Mp3Icon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 420 500"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M210 266.667H193.846V233.333H210C214.284 233.333 218.393 235.089 221.422 238.215C224.451 241.34 226.153 245.58 226.153 250C226.153 254.42 224.451 258.659 221.422 261.785C218.393 264.911 214.284 266.667 210 266.667Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 50C0 36.7392 5.10576 24.0215 14.1941 14.6447C23.2824 5.26784 35.6087 0 48.4615 0L313.611 0L420 109.767V450C420 463.261 414.894 475.979 405.806 485.355C396.718 494.732 384.391 500 371.538 500H48.4615C35.6087 500 23.2824 494.732 14.1941 485.355C5.10576 475.979 0 463.261 0 450V50ZM210 200H161.538V366.667H193.846V300H210C222.853 300 235.179 294.732 244.268 285.355C253.356 275.979 258.462 263.261 258.462 250C258.462 236.739 253.356 224.021 244.268 214.645C235.179 205.268 222.853 200 210 200ZM59.8985 204.867C57.6392 202.53 54.7583 200.938 51.6209 200.292C48.4836 199.646 45.2311 199.977 42.2758 201.241C39.3205 202.504 36.7955 204.645 35.0209 207.392C33.2463 210.138 32.302 213.366 32.3077 216.667V366.667H64.6154V256.9L80.7692 273.567L96.9231 256.9V366.667H129.231V216.667C129.236 213.366 128.292 210.138 126.518 207.392C124.743 204.645 122.218 202.504 119.263 201.241C116.307 199.977 113.055 199.646 109.918 200.292C106.78 200.938 103.899 202.53 101.64 204.867L80.7692 226.433L59.8985 204.867ZM339.231 233.333H290.769V200H371.538C374.538 200 377.479 200.862 380.031 202.489C382.583 204.116 384.645 206.445 385.987 209.213C387.329 211.982 387.896 215.081 387.627 218.163C387.358 221.246 386.262 224.191 384.462 226.667L353.737 268.933C364.807 272.495 374.272 280.033 380.414 290.18C386.557 300.327 388.97 312.41 387.214 324.235C385.459 336.061 379.652 346.845 370.848 354.631C362.044 362.416 350.826 366.687 339.231 366.667H290.769V333.333H339.231C343.515 333.333 347.624 331.577 350.653 328.452C353.683 325.326 355.385 321.087 355.385 316.667C355.385 312.246 353.683 308.007 350.653 304.882C347.624 301.756 343.515 300 339.231 300H323.077C320.077 300 317.136 299.138 314.584 297.511C312.032 295.884 309.97 293.555 308.629 290.787C307.287 288.018 306.719 284.919 306.988 281.837C307.258 278.754 308.354 275.809 310.154 273.333L339.231 233.333Z"
        fill={fill}
      />
    </svg>
  );
};

export default Mp3Icon;
