import React, { FC, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { routes } from './routes'
import PrivateRoute from './PrivateRoute'
import { getSource } from '../helpers/util'
const MainRouter: FC = () => {
  const [ifSessionRestoreAdmin, setifSessionRestoreAdmin] = useState<boolean>(
    false
  )

  const SourceState = getSource();
  React.useEffect(() => {
    if (SourceState) {
      setifSessionRestoreAdmin(true)
    } else {
      setifSessionRestoreAdmin(false)
    }
  }, [SourceState, ifSessionRestoreAdmin])
  return (
    <Router>
      <Switch>
        <Route
          path={routes.DEFAULT.route}
          component={routes.DEFAULT.component}
          exact
        />
        <Route
          path={routes.SIGNIN.routeAdmin}
          component={routes.SIGNIN.component}
          exact
        />
        <Route
          path={routes.RECOVER.routeAdmin}
          component={routes.RECOVER.component}
          exact
        />
        <Route
          path={routes.SET_PASSWORD.routeAdmin}
          component={routes.SET_PASSWORD.component}
          exact
        />
        <Route
          path={routes.SIGNIN.routeUser}
          component={routes.SIGNIN.component}
          exact
        />
        <Route
          path={routes.RECOVER.routeUser}
          component={routes.RECOVER.component}
          exact
        />
        <Route
          path={routes.SET_PASSWORD.routeUser}
          component={routes.SET_PASSWORD.component}
          exact
        />

        {/* {SourceState === "0" ? (
          <>  */}
        <PrivateRoute
          path={routes.ADMIN_USERLIST.route}
          component={routes.ADMIN_USERLIST.component}
        />
        <PrivateRoute
          path={routes.FILES.routeAdmin}
          component={routes.FILES.component}
        />
        <PrivateRoute
          path={routes.PROFILE.routeAdmin}
          component={routes.PROFILE.component}
        />
         {/* </>
        ):(
          <> */}
          
          <PrivateRoute
          path={routes.FILES.routeUser}
          component={routes.FILES.component}
        />
          <PrivateRoute
          path={routes.PROFILE.routeUser}
          component={routes.PROFILE.component}
        />
          {/* </>
        )
        }  */}
      </Switch>
    </Router>
  )
}
export default MainRouter
