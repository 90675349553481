import React, { useContext, useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";

import { AuthenticateResponse } from '../api/fetch-client'

const AuthContext = React.createContext({})

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>(null)
  const [currentUserDetails, setCurrentUserDetails] = useState<any>(null)
  const [token, setToken] = useState<any>(null)
  const [signed, setSigned] = useState<boolean>(false)
  async function UpdateUser (user: AuthenticateResponse, profile: any) {
    if (user) {
      setUser(user)
      localStorage.setItem('@App:user', JSON.stringify(user))
      localStorage.setItem('@App:token', user.jwtToken as string)
      localStorage.setItem('@App:source', user.role as string)
    }
    if (profile) {
      setCurrentUserDetails(user)
    }
  }
  useEffect(() => {
    const storagedUser = localStorage.getItem("@App:user");
    const storagedToken = localStorage.getItem("@App:token");
    console.log(storagedToken);
    if (storagedToken && storagedUser) {
      if (storagedToken !== "") {

        let decodedToken:any = jwt_decode(storagedToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();

        // JWT exp is in seconds
        if (decodedToken?.exp * 1000 < currentDate.getTime()) {
          console.log("Token expired.");
          setSigned(false);
          Logout();
        } else {
          console.log("Valid token");
          const storagedUserObj = JSON.parse(storagedUser);
          setSigned(true);
          setToken(storagedToken);
          if (storagedUserObj.userData) {
            setCurrentUserDetails(JSON.parse(storagedUserObj.userData));
          }
        }
      }



    }
  }, [user]);
  function Logout () {
    setUser(null)
    setSigned(false)
    localStorage.removeItem('@App:user')
    localStorage.removeItem('@App:token')
  }
  return (
    <AuthContext.Provider
      value={{
        signed,
        user,
        token,
        currentUserDetails,
        UpdateUser,
        Logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth () {
  const context: any = useContext(AuthContext)

  return context
}
