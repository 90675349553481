import * as yup from "yup";
import { useFormik } from "formik";
import React, { FC, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import MuiTextField from "../../../components/MuiTextField";
import { useTranslation } from "react-i18next";

import {
  Typography,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../../context/UserContext";
import { paths } from "../../../routing/paths";
import { Loading } from "../../../components/icons/Loading";
import Logo from "../../../components/logo/logo.png";
import { getSource, isSignin } from "../../../helpers/util";
import { AuthService } from "../../../services/AuthServices";
import { SnackbarContext } from "../../../context/SnackbarContext";
import {
  ApiException,
  AuthenticateRequest,
  AuthenticateResponse,
  Client,
  IAuthenticateRequest,
  Query,
  Role,
} from "../../../api/fetch-client";
interface ITheme {
  history: string;
  _theme?: {
    breakpoints: any;
  };
  breakpoints?: any;
}
interface FormFields {
  email?: any;
  password?: any;
  isRemember?: boolean;
}
type ValuesSchema = {
  email?: any;
  password?: any;
};
const Login: FC = (props) => {
  const { t } = useTranslation();

  const { UpdateUser } = useAuth();
  const { setSnack } = useContext<any>(SnackbarContext);

  const SignInState = isSignin();
  const Source = getSource();
  const matches = useMediaQuery(
    (_theme: ITheme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const onClickNavigation = () => {
    history.push(paths.ADMIN_RECOVER);
  };

  const validationSchema = yup.object<ValuesSchema>({
    email: yup.string().email("Email is invalid").required("Email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });
  const { handleSubmit, handleBlur, handleChange, touched, values, errors } =
    useFormik<IAuthenticateRequest>({
      initialValues: {
        email: "",
        password: "",
        isRemember: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        Loginfns(values);
      },
    });
  function Loginfns(values: IAuthenticateRequest) {
    setIsLoading(true);

    Query.Client.login(values as AuthenticateRequest)
      .then((response: any) => {
        if (response.success) {
          let responseDate: AuthenticateResponse = response && response.data;
          UpdateUser(responseDate);
          setSnack({
            message: "User successfully logged In",
            open: true,
            color: "success",
          });
          history.push(paths.USER_FILES);
        } else {
          setSnack({
            message: t(response.statusCode),
            open: true,
            color: "error",
          });
        }
        setIsLoading(false);
      })
      .catch((error: ApiException) => {
        setSnack({ message: error.message, open: true, color: "error" });
        setIsLoading(false);
      });
  }
  useEffect(() => {
    if (SignInState) {
      history.push(paths.USER_FILES);
    }
  }, [props, SignInState]);
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          sx={{
            height: "100vh",
            position: "relative",
            bgcolor: "primary.main",
          }}
        >
          <Card
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: matches ? "100%" : "400px",
              padding: 40,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <CardContent
              sx={{
                padding: "15px 30px 30px 30px",
              }}
            >
              <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{
                      height: "129px",
                      width: "160px",
                    }}
                  >
                    <img width={"100%"} height={"100%"} src={Logo} alt="logo" />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <MuiTextField
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={"E-mail"}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiTextField
                    id="password"
                    type="password"
                    placeholder={"Password"}
                    isProtected="true"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values?.isRemember}
                        onChange={handleChange}
                        name="isRemember"
                        color="primary"
                        sx={{ borderColor: "primary.main" }}
                      />
                    }
                    label="Remember me"
                  />
                </Grid>

                <Grid item xs={12} justifyContent="center" alignItems="center">
                  {!isLoading ? (
                    <>
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        type="submit"
                        sx={{
                          minWidth: "100%",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("Login")}
                      </Button>
                    </>
                  ) : (
                    <Loading align={"center"} />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    onClick={onClickNavigation}
                    sx={{
                      textAlign: "center",
                      color: "primary.main",
                      cursor: "pointer",
                    }}
                  >
                    Forgot your password?
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </form>
    </>
  );
};

export default Login;
