import React, { FC } from "react";
interface Props {
  fill?: any;
  width?: number;
  height?: number;
}
const CsvIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 380 500"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M221.264 132.812V0H23.7069C10.5693 0 0 10.4492 0 23.4375V476.562C0 489.551 10.5693 500 23.7069 500H355.603C368.741 500 379.31 489.551 379.31 476.562V156.25H244.971C231.932 156.25 221.264 145.703 221.264 132.812ZM126.437 273.438C126.437 277.754 122.901 281.25 118.534 281.25H110.632C101.9 281.25 94.8276 288.242 94.8276 296.875V328.125C94.8276 336.758 101.9 343.75 110.632 343.75H118.534C122.901 343.75 126.437 347.246 126.437 351.562V367.188C126.437 371.504 122.901 375 118.534 375H110.632C84.4459 375 63.2184 354.014 63.2184 328.125V296.875C63.2184 270.986 84.4459 250 110.632 250H118.534C122.901 250 126.437 253.496 126.437 257.812V273.438ZM170.166 375H158.046C153.68 375 150.144 371.504 150.144 367.188V351.562C150.144 347.246 153.68 343.75 158.046 343.75H170.166C176.043 343.75 180.449 340.332 180.449 337.285C180.449 336.016 179.708 334.688 178.355 333.535L156.732 315.205C148.366 308.154 143.565 298.135 143.565 287.725C143.565 266.924 162.353 250.01 185.457 250.01H197.557C201.924 250.01 205.46 253.506 205.46 257.822V273.447C205.46 277.764 201.924 281.26 197.557 281.26H185.437C179.56 281.26 175.154 284.678 175.154 287.725C175.154 288.994 175.895 290.322 177.249 291.475L198.871 309.805C207.238 316.855 212.038 326.875 212.038 337.285C212.048 358.076 193.27 375 170.166 375ZM252.874 257.812V278.125C252.874 297.92 258.504 317.354 268.678 333.672C278.852 317.363 284.483 297.92 284.483 278.125V257.812C284.483 253.496 288.019 250 292.385 250H308.19C312.556 250 316.092 253.496 316.092 257.812V278.125C316.092 312.773 303.369 345.4 280.255 370.01C277.272 373.184 273.074 375 268.678 375C264.283 375 260.084 373.184 257.101 370.01C233.987 345.4 221.264 312.773 221.264 278.125V257.812C221.264 253.496 224.801 250 229.167 250H244.971C249.337 250 252.874 253.496 252.874 257.812ZM372.396 102.539L275.691 6.83594C271.246 2.44141 265.221 0 258.899 0H252.874V125H379.31V119.043C379.31 112.891 376.841 106.934 372.396 102.539Z"
        fill={fill}
      />
    </svg>
  );
};

export default CsvIcon;
