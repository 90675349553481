import React, { FC } from 'react'
interface Props {
  fill?: any
  width?: number
  height?: number
}
const ImageIcon: FC<Props> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 12.8V0H3.2V12.8H16ZM7.2 8L8.824 10.168L11.2 7.2L14.4 11.2H4.8L7.2 8ZM0 3.2V16H12.8V14.4H1.6V3.2H0Z'
        fill={fill}
      />
    </svg>
  )
}

export default ImageIcon
